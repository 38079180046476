import React, { useState } from 'react';
import * as yup from 'yup';
import { Button, Input, TimezoneOffset } from '../../../../components';
import OneWayTripIcon from '../../../../assets/icons/oneWayTrip.svg';
import RoundTripIcon from '../../../../assets/icons/roundTrip.svg';
import styles from './styles.module.scss';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import useMediaQuery from '@mui/material/useMediaQuery';
import { AppState } from '../../../../types/stores';
import checkboxIcon from '../../../../assets/icons/checkbox.svg';
import { Box, Checkbox, Grid, Typography } from '@mui/material';
import commonStyles from '../../../../styles/common.module.scss';

interface Props {
  onClick: (value: any, fieldName: string) => void;
  onClickBack: () => void;
  onClickNext: () => void;
}

const SelectTripTypeForm: React.FC<Props> = ({ onClick, onClickBack, onClickNext }) => {
  const { isOneWay, willCall, pickUpTime } = useSelector(
    ({ roundTripInfo }: AppState) => roundTripInfo
  );
  const [activeTripOption, setActiveTripOption] = useState(isOneWay ? 1 : 0);
  const { t } = useTranslation();
  const media = useMediaQuery('(max-width:576px)');

  const tripOptions = [
    {
      id: 'trip-option-round-trip',
      title: t('roundTrip'),
      icon: RoundTripIcon,
    },
    {
      id: 'trip-option-one-way',
      title: t('oneWay'),
      icon: OneWayTripIcon,
    },
  ];

  const {
    values,
    handleSubmit,
    handleChange,
    setFieldValue,
    touched,
    errors,
    setErrors,
    setTouched,
  } = useFormik({
    // TODO: add validation if needed
    validationSchema: yup.object({
      willCall: yup.boolean(),
      pickUpTime: yup.string().when('willCall', {
        is: (willCall: boolean) => !willCall && !values.isOneWay,
        then: yup.string().required(t('enterPickupTime')),
      }),
    }),
    initialValues: {
      isOneWay: !!isOneWay,
      pickUpTime: pickUpTime || '',
      willCall: !!willCall,
    },
    onSubmit: () => {
      onClick(values, 'roundTripInfo');
      onClickNext();
    },
  });

  const onSelectOption = (index: number) => {
    setActiveTripOption(index);
    setFieldValue('isOneWay', !(index === 0));
  };

  const onPressWillCall = (value: boolean) => {
    setErrors({});
    setTouched({});
    setFieldValue('willCall', value);
    setFieldValue('pickUpTime', '');
  };

  const renderTripOptions = () =>
    tripOptions.map(
      ({ id, title, icon }: { id: string; title: string; icon: string }, index: number) => {
        const imgClassName = activeTripOption === index ? 'activeImage' : 'inactiveImage';

        return (
          <Grid key={title} item xs={6}>
            <div className={styles.wrapRoundTrip}>
              <p className={styles.optionTitle}>{title}</p>
              <a onClick={() => onSelectOption(index)} id={id}>
                <img className={styles[imgClassName]} src={icon} />
              </a>
            </div>
          </Grid>
        );
      }
    );

  const renderPickUpTimeInput = () =>
    !values.isOneWay && (
      <Grid className={styles.timeInputContainer} item xs={media ? 12 : 8}>
        <Input
          type="time"
          InputLabelProps={{
            shrink: true,
          }}
          select={false}
          id="pickUpTime"
          name="pickUpTime"
          sx={{ width: '100%' }}
          onChange={handleChange}
          value={values.pickUpTime}
          disabled={!!values.willCall}
          text={t('roundTripPickUpTime')}
          helperText={touched.pickUpTime && errors.pickUpTime}
          error={!!touched.pickUpTime && Boolean(errors.pickUpTime)}
          afterTextField={<TimezoneOffset />}
        />
        <div className={styles.checkboxContainer}>
          <Checkbox
            size="medium"
            value={values.willCall}
            checked={values.willCall}
            checkedIcon={<img src={checkboxIcon} />}
            onChange={() => onPressWillCall(!values.willCall)}
          />
          <p>{t('willCall')}</p>
        </div>
      </Grid>
    );

  const renderWillCallHints = () =>
    !values.isOneWay && (
      <>
        <Typography className={styles.willCallHint1}>{t('willCallHint1')}</Typography>
        <Typography className={styles.willCallHint2}>{t('willCallHint2')}</Typography>
      </>
    );

  return (
    <div className={styles.wrap}>
      <form onSubmit={handleSubmit}>
        <div className={styles.container}>
          <p className={commonStyles.boldText}>{t('selectTripType')}</p>
          <Grid container>{renderTripOptions()}</Grid>
          <div className={styles.wrapPickupTime}>
            {renderPickUpTimeInput()}
            {renderWillCallHints()}
          </div>
        </div>
        <div className={commonStyles.wrapButton}>
          <Button label={t('back')} outlined onClick={onClickBack} />
          <Button label={t('next')} />
        </div>
      </form>
    </div>
  );
};

export default SelectTripTypeForm;
