import React from 'react';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { useStyles, theme } from './styles';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';

interface Props {
  value?: string;
  type?: string;
  id?: string;
  name?: string;
  label?: string;
  error?: boolean | undefined;
  placeholder?: string;
  text?: string;
  helperText?: any;
  onChange?: (e: React.ChangeEvent<any>) => void;
  onBlur?: any;
  width?: number;
  sx?: any;
  endAdornment?: any;
  afterTextField?: React.ReactNode;
  isOptional?: boolean;
  renderTip?: () => void;
}

const Input: React.FC<Props & TextFieldProps> = (props) => {
  const { root, wrapText, optionalText } = useStyles();
  const { afterTextField, text, isOptional = false, renderTip } = props;
  const { t } = useTranslation();

  return (
    <ThemeProvider theme={theme}>
      <div className={root}>
        <div className={wrapText}>
          <Typography>{text}</Typography>
          {isOptional && <span className={optionalText}>&nbsp;{t('optional')}</span>}
          {renderTip?.()}
        </div>
        <div className={wrapText}>
          <TextField {...props} variant="outlined" size="small" />
          {afterTextField}
        </div>
      </div>
    </ThemeProvider>
  );
};

export default Input;
