import { makeStyles } from '@mui/styles';
import colors from '../../styles/colors';

export const useStyles = makeStyles({
  root: {},
  valueEnding: {
    position: 'absolute',
    zIndex: 10,
    marginTop: 49,
    fontSize: 16,
    color: colors.inputPlaceholder,
    fontFamily: 'monospace',
  },
  inputField: {
    '& input': {
      fontFamily: 'monospace',
    },
  },
});
