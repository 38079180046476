import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import { Input, Button, MaskedInput, Search } from '../../../../components';
import styles from './styles.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../../types/stores';
import * as yup from 'yup';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTranslation } from 'react-i18next';
import MUIButton from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { AutocompleteOption, LocationInfo, ParsedAddress } from '../../../../types';
import { bindActionCreators } from 'redux';
import { appActions } from '../../../../store/app';
import explodeAddress from '../../../../utils/addressParser/parseAddress.js';
import { isAddressValid, getIsRequestStateBlocking } from '../../../../utils';
import { Typography } from '@mui/material';
import colors from '../../../../styles/colors';
import tipIcon from '../../../../assets/icons/tip.svg';
import commonStyles from '../../../../styles/common.module.scss';
import ReactTooltip from 'react-tooltip';

interface Props {
  onClickBack: () => void;
  onClickNext: () => void;
  setValue: (value: LocationInfo, fieldName: string) => void;
}

const DropOffAddressForm: React.FC<Props> = ({ onClickBack, onClickNext, setValue }) => {
  const [isLocationValid, setIsLocationValid] = useState(true);
  const [isManualEntering, setIsManualEntering] = useState(false);
  const [formIsVisible, setFormIsVisible] = useState(false);
  const {
    city,
    state,
    notes,
    zipCode,
    address1,
    address2,
    latitude,
    longitude,
    searchInput,
    addressName,
    searchLoading,
    contactNumber,
    contactNumberExt,
    searchLocationAutocompleteOptions,
    pickLocationStatus,
  } = useSelector(
    ({
      dropOffLocation,
      searchLoading,
      searchLocationAutocompleteOptions,
      pickLocationStatus,
    }: AppState) => ({
      ...dropOffLocation,
      searchLoading,
      searchLocationAutocompleteOptions,
      pickLocationStatus,
    })
  );
  const { searchLocation, pickLocation } = bindActionCreators(appActions, useDispatch());
  const options = searchLocationAutocompleteOptions.map(
    ({ address }: AutocompleteOption) => address
  );
  const { t } = useTranslation();
  const matches = useMediaQuery('(max-width:576px)');

  const { handleBlur, setFieldValue, handleSubmit, handleChange, errors, touched, values } =
    useFormik({
      validationSchema: yup.object({
        address1: yup.string().required(t('pleaseEnterAdress')),
        city: yup.string().required(t('pleaseEnterCity')),
        state: yup.string().required(t('pleaseEnterState')),
        zipCode: yup.string().required(t('pleaseEnterZipCode')),
        contactNumber: yup
          .string()
          .min(10, t('enterValidNumber'))
          .when('zipCode', {
            is: () => !!values.contactNumber,
            then: yup.string().phone('US', true, t('enterValidNumber')),
          }),
      }),
      initialValues: {
        city: city || '',
        notes: notes || '',
        state: state || '',
        zipCode: zipCode || '',
        address2: address2 || '',
        address1: address1 || '',
        addressName: addressName || '',
        searchInput: searchInput || '',
        contactNumber: contactNumber || '',
        contactNumberExt: contactNumberExt || '',
      },
      onSubmit: () => {
        setValue(
          {
            latitude,
            longitude,
            searchInput: values.searchInput,
            city: values.city,
            state: values.state,
            notes: values.notes,
            zipCode: values.zipCode,
            address1: values.address1,
            address2: values.address2,
            addressName: values.addressName,
            contactNumber: values.contactNumber,
            contactNumberExt: values.contactNumberExt,
          },
          'dropOffLocation'
        );
        values.address1 && onClickNext();
      },
    });

  useEffect(() => {
    setFieldValue('city', city);
    setFieldValue('state', state);
    setFieldValue('zipCode', zipCode);
    setFieldValue('address1', address1);
  }, [address1]);

  const renderTipContent = () => (
    <div>
      <p>{t('notesTip')}</p>
    </div>
  );

  const renderNotesTip = () => (
    <>
      <ReactTooltip getContent={renderTipContent} />
      <img
        src={tipIcon}
        data-tip="tooltip"
        data-type="light"
        data-class={commonStyles.tipContainer}
      />
    </>
  );
  const renderForm = () =>
    (address1 || isManualEntering || formIsVisible) && (
      <>
        <Grid item xs={matches ? 12 : 4}>
          <Input
            id="addressName"
            name="addressName"
            text={t('textHome')}
            sx={{ width: '100%' }}
            onChange={handleChange}
            value={values.addressName}
            placeholder={t('placeholderHospital')}
          />
        </Grid>
        <Grid item xs={matches ? 12 : 5}>
          <MaskedInput
            isOptional
            text={t('contactNumber')}
            placeholder="(555) 000-0000"
            id="contactNumber"
            name="contactNumber"
            mask="(999) 999-9999"
            sx={{ width: '100%' }}
            value={values.contactNumber}
            onBlur={handleBlur}
            onChange={handleChange}
            error={touched.contactNumber && Boolean(errors.contactNumber)}
            helperText={touched.contactNumber && errors.contactNumber}
          />
        </Grid>
        <Grid item xs={matches ? 12 : 3}>
          <MaskedInput
            isOptional
            text={t('ext')}
            placeholder="00"
            id="contactNumberExt"
            name="contactNumberExt"
            mask="99"
            sx={{ width: '100%' }}
            value={values.contactNumberExt}
            onBlur={handleBlur}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <Input
            id="address1"
            name="address1"
            text={t('addressLine1')}
            sx={{ width: '100%' }}
            value={values.address1}
            onChange={handleChange}
            disabled={!isManualEntering}
            placeholder={t('ex') + '145 4th Avenue'}
            helperText={touched.address1 && errors.address1}
            error={touched.address1 && Boolean(errors.address1)}
          />
        </Grid>
        <Grid item xs={12}>
          <Input
            id="address2"
            name="address2"
            text={t('addressLine2')}
            sx={{ width: '100%' }}
            onChange={handleChange}
            value={values.address2}
            placeholder={t('addressLine2Placeholder')}
          />
        </Grid>
        <Grid item xs={matches ? 12 : 5}>
          <Input
            id="city"
            name="city"
            text={t('city')}
            value={values.city}
            sx={{ width: '100%' }}
            onChange={handleChange}
            placeholder="ex. New York"
            disabled={!isManualEntering}
            helperText={touched.city && errors.city}
            error={touched.city && Boolean(errors.city)}
          />
        </Grid>
        <Grid item xs={matches ? 7 : 4}>
          <Input
            id="state"
            name="state"
            text={t('state')}
            value={values.state}
            placeholder="ex. CA"
            sx={{ width: '100%' }}
            onChange={handleChange}
            disabled={!isManualEntering}
            helperText={touched.state && errors.state}
            error={touched.state && Boolean(errors.state)}
          />
        </Grid>
        <Grid item xs={matches ? 5 : 3}>
          <MaskedInput
            mask="99999"
            id="zipCode"
            name="zipCode"
            text={t('zip')}
            value={values.zipCode}
            sx={{ width: '100%' }}
            placeholder="ex. 68552"
            onChange={handleChange}
            disabled={!isManualEntering}
            helperText={touched.zipCode && errors.zipCode}
            error={touched.zipCode && Boolean(errors.zipCode)}
          />
        </Grid>
        <Grid item xs={12}>
          <Input
            multiline
            isOptional
            id="notes"
            name="notes"
            text={t('notes')}
            value={values.notes}
            sx={{ width: '100%' }}
            onChange={handleChange}
            renderTip={renderNotesTip}
            placeholder={t('notesPlaceholder')}
          />
        </Grid>
      </>
    );

  const onClickOption = ({ place_id }: AutocompleteOption) => {
    pickLocation(place_id!, 'dropOffLocation');
    setFormIsVisible(true);
  };

  const onEnterLocation = (_: React.SyntheticEvent, value: string) => {
    setIsLocationValid(true);
    setFormIsVisible(false);
    setFieldValue('searchInput', value);
    searchLocation(value);
  };

  const onChange = (option: string) => {
    if (option) {
      const pickedLocation = searchLocationAutocompleteOptions.find(
        ({ address }: AutocompleteOption) => address === option
      );
      onClickOption(pickedLocation!);
    }
  };

  const validateLocation = () => {
    explodeAddress(values.searchInput, (parsedAddress: ParsedAddress) => {
      if (!isAddressValid(parsedAddress)) setIsLocationValid(false);
    });
  };

  const renderManualEnteringButton = () => (
    <div className={styles.enterManualContainer}>
      <MUIButton
        color={'info'}
        disabled={isManualEntering}
        onClick={() => setIsManualEntering(true)}
      >
        {t('enterManualAddress')}
      </MUIButton>
    </div>
  );

  const isPickLocationBlocking = getIsRequestStateBlocking(pickLocationStatus);
  const showSubmitLoading = searchLoading || isPickLocationBlocking;

  const searchInputError = touched.searchInput && Boolean(errors.searchInput);

  return (
    <div className={styles.wrap}>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={matches ? 2 : 4} sx={{ padding: matches ? `0 4vw 4vh` : 0 }}>
          <Grid item xs={12}>
            <p className={commonStyles.boldText}>{t('pleaseEnterDestinationAddress')}</p>
            <Search
              id="dropOffSearchInput"
              options={options}
              handleChange={onChange}
              loading={searchLoading}
              value={values.searchInput}
              onInputChange={onEnterLocation}
              placeholder={t('ex') + '145 4th Avenue'}
              error={touched.searchInput && Boolean(errors.searchInput)}
            />
            {searchInputError && (
              <Typography color={colors.orange}>{t('pleaseChooseLocation')}</Typography>
            )}
            {renderManualEnteringButton()}
          </Grid>
          {renderForm()}
        </Grid>
        <div className={commonStyles.wrapButton}>
          <Button label={t('back')} outlined onClick={onClickBack} />
          <Button submitLoading={showSubmitLoading} label={t('next')} onClick={validateLocation} />
        </div>
      </form>
    </div>
  );
};

export default DropOffAddressForm;
