import { makeStyles } from '@mui/styles';
import { createTheme } from '@mui/material/styles';
import colors from '../../styles/colors';
const { grey, darkGrey2, red } = colors;

export const useStyles = makeStyles({
  root: {
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '& .MuiOutlinedInput-root': {
      backgroundColor: grey,
      margin: 0,
      color: darkGrey2,
    },
    '& .MuiOutlinedInput-root.Mui-error': {
      border: `1px solid ${red}`,
    },
    '& .MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select': {
      fontWeight: 400,
      fontSize: 16,
      color: darkGrey2,
      fontStyle: 'normal',
      paddingLeft: 16,
    },
    '& .MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input': {
      paddingTop: 16,
      paddingBottom: 16,
    },
    '& .MuiTypography-root': {
      fontFamily: 'Mulish',
      fontSize: 16,
      marginBottom: 6,
    },
  },
  wrapText: {
    display: 'flex',
    alignItems: 'center',
  },
});

export const theme = createTheme({
  components: {
    MuiList: {
      styleOverrides: {
        root: {
          backgroundColor: grey,
          boxShadow: 'none',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          ['@media screen and (max-width: 576px)']: {
            maxHeight: 250,
            left: `10px !important`,
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          color: darkGrey2,
          fontSize: 14,
          textOverflow: 'ellipsis',
          fontFamily: 'Mulish',
          ['@media screen and (max-width: 576px)']: {
            fontSize: '4vw',
          },
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: { root: { marginRight: 15 } },
    },
  },
});
