import React, { useEffect } from 'react';
import styles from './styles.module.scss';
import StartForm from './component/StartForm';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AppState } from '../../types/stores';
import { appActions } from '../../store/app';
import { Stepper } from '../../components';
import ChooseProviderForm from './component/ChooseProviderForm';
import MemberDetailsForm from './component/MemberDetailsForm';
import EnterMemderIDForm from './component/EnterMemderIDForm';
import ChoosePurposeForm from './component/ChoosePurposeForm';
import AssistanceForm from './component/AssistanceForm';
import ChooseLanguageForm from './component/ChooseLanguageForm';
import ContactDetailsForm from './component/ContactDetailsForm';
import PickUpAddressForm from './component/PickUpAddressForm';
import DropOffAddressForm from './component/DropOffAddressForm';
import AppoitmentTimeForm from './component/AppoitmentTimeForm';
import SelectTripTypeForm from './component/SelectTripTypeForm';
import VerifyForm from './component/VerifyForm';
import usePrompt from '../../hooks/usePrompt.js';

const formsWithoutStepper = [0, 1];

const ScheduleRequest: React.FC = () => {
  const { step, memberId, healthPlan, referenceId, specifyOtherPurpose } = useSelector(
    (state: AppState) => state
  );
  const { nextCall, backCall, selectPlan, setFieldValue } = bindActionCreators(
    appActions,
    useDispatch()
  );
  usePrompt(step > 2);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [step, referenceId]);

  const renderStepper = () =>
    !referenceId &&
    !formsWithoutStepper.includes(step) && (
      <div className={styles.wrapStepper}>
        <Stepper activeStep={step - 2} />
      </div>
    );

  const getFormContent = (step: number) => {
    switch (step) {
      case 0:
        return (
          <ChooseLanguageForm
            onClickNext={nextCall}
            setValue={setFieldValue}
            onClickBack={backCall}
          />
        );
      case 1:
        return <StartForm onClickNext={nextCall} onClickBack={backCall} />;
      case 2:
        return (
          <ChooseProviderForm
            value={healthPlan}
            onClick={selectPlan}
            onClickBack={backCall}
            onClickNext={nextCall}
          />
        );
      case 3:
        return <EnterMemderIDForm onClickBack={backCall} onClickNext={nextCall} value={memberId} />;
      case 4:
        return (
          <MemberDetailsForm
            onClickNext={nextCall}
            onClickBack={backCall}
            setValue={setFieldValue}
          />
        );
      case 5:
        return (
          <ContactDetailsForm
            onClickNext={nextCall}
            onClickBack={backCall}
            setValue={setFieldValue}
          />
        );
      case 6:
        return (
          <PickUpAddressForm
            onClickNext={nextCall}
            onClickBack={backCall}
            setValue={setFieldValue}
          />
        );
      case 7:
        return (
          <DropOffAddressForm
            onClickNext={nextCall}
            onClickBack={backCall}
            setValue={setFieldValue}
          />
        );
      case 8:
        return (
          <ChoosePurposeForm
            onClick={setFieldValue}
            onClickBack={backCall}
            onClickNext={nextCall}
            specifyOtherPurpose={specifyOtherPurpose}
          />
        );
      case 9:
        return (
          <AppoitmentTimeForm
            setValue={setFieldValue}
            onClickBack={backCall}
            onClickNext={nextCall}
          />
        );
      case 10:
        return (
          <AssistanceForm onClick={setFieldValue} onClickBack={backCall} onClickNext={nextCall} />
        );
      case 11:
        return (
          <SelectTripTypeForm
            onClick={setFieldValue}
            onClickBack={backCall}
            onClickNext={nextCall}
          />
        );
      case 12:
        return <VerifyForm onClickBack={backCall} />;
      default:
        return '';
    }
  };

  return (
    <div className={styles.wrapper}>
      {renderStepper()}
      <div className={styles.wrap}>
        <div className={styles.wrapContent}>{getFormContent(step)}</div>
      </div>
    </div>
  );
};

export default ScheduleRequest;
