export default {
  blue: '#3FC5EA',
  black: '#000',
  orange: '#FF774D',
  grey: '#F6F9FC',
  darkGrey: '#8AA3B9',
  dark: '#1E0705',
  lightBlue: '#8DE0F6',
  white: '#ffffff',
  black1: '#000E42',
  stepperBackground: '#70E1F8',
  stepperLabel: '#A5ECFF',
  red: '#FF5244',
  darkGrey2: '#3D5E7B',
  inputPlaceholder: '#3C5D7B',
};
