import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import { fullBuildVersion } from './version';

export function sentryInit(config = {}) {
  const sentryDsn = process.env.REACT_APP_SENTRY_DSN;

  // Sentry should always be configured with REACT_APP_SENTRY_ENVIRONMENT
  let sentryEnv = process.env.REACT_APP_SENTRY_ENVIRONMENT;
  // But in case it's not, we'll set a default depending on NODE_ENV
  if (!sentryEnv) {
    sentryEnv = `unknown-${process.env.NODE_ENV}`;
  }

  const defaults = {
    dsn: sentryDsn,
    environment: sentryEnv,
    release: fullBuildVersion,
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  };

  const sentryConfig = {
    ...defaults,
    ...config,
  };
  Sentry.init(sentryConfig);

  console.info(`Initialized Sentry with config:`, {
    dsn: sentryDsn,
    environment: sentryEnv,
    release: fullBuildVersion,
  });
}
