// Base URL for API
// For API Gateway, should be e.g. 'https://acceptance-api.scheduling-form.aws.healtherides.com/scheduling-form-api'
// For HQ, should be e.g. 'https://acceptance.hq-staging.aws.carecar.co/'
const baseUrl = process.env.REACT_APP_API_BASE_URL || 'https://acceptance-api.scheduling-form.aws.healtherides.com/scheduling-form-api';

// Set to true if using HQ API style paths
const useHqApi = process.env.REACT_APP_USE_HQ_API === "1" || process.env.REACT_APP_USE_HQ_API === "true";

// API paths when interacting with API Gateway (default)
const apiGatewayPaths = {
  locationSearch: 'location-autocomplete',
  locationDetails: 'location-details',
  tripEstimate: 'trip-estimate',
  tripRequest: 'trip-request',
};

// API paths when interacting directly with HQ API
const hqPaths = {
  locationSearch: 'api/v1/self_service/location/autocomplete',
  locationDetails: 'api/v1/self_service/location/details',
  tripEstimate: 'api/v1/self_service/trip/estimate',
  tripRequest: 'api/v1/self_service/trip/request',
};

const paths = useHqApi ? hqPaths : apiGatewayPaths;
export default {
  API_BASE_URL: baseUrl,
  ...paths,
};
