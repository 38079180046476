import React, { useState } from 'react';
import * as yup from 'yup';
import { Select, Button } from '../../../../components';
import { healthPlans, unavailableHealthPlans } from '../../../../helpers/constants';
import { HealthPlan } from '../../../../types';
import styles from './styles.module.scss';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import commonStyles from '../../../../styles/common.module.scss';
import useMediaQuery from '@mui/material/useMediaQuery';

interface Props {
  value?: HealthPlan;
  onClick: (healtPlan: number) => void;
  onClickBack: () => void;
  onClickNext: () => void;
}

const ChooseProviderForm: React.FC<Props> = ({ onClick, value, onClickBack, onClickNext }) => {
  const [selectedPlan, setSelectedPlan] = useState<HealthPlan>();
  const plans = [...healthPlans, ...unavailableHealthPlans];
  const valueArray = plans.map(({ name }) => name);
  const matches = useMediaQuery('(max-width:576px)');
  const valueArrayMobile = plans.map(({ name, nameMobile }) => nameMobile ?? name);
  const { t } = useTranslation();

  const onSubmit = () => {
    if (!selectedPlan?.isUnavailable) onClickNext();
  };

  const formik = useFormik({
    validationSchema: yup.object({
      healthPlan: yup.string().required(t('pleaseSelectOne')),
    }),
    initialValues: {
      healthPlan: value ? value.name : '',
    },
    onSubmit,
  });

  const handleChange = (name: any) => {
    onClick((matches ? valueArrayMobile : valueArray).indexOf(name));
    formik.setFieldValue('healthPlan', name);
    setSelectedPlan(plans[valueArray.indexOf(name)]);
  };

  const renderUnavailableError = () =>
    selectedPlan?.isUnavailable && <p className={styles.errorText}>{t('unavailablePlan')}</p>;

  return (
    <div className={styles.wrap}>
      <form onSubmit={formik.handleSubmit}>
        <div className={styles.container}>
          <p className={commonStyles.boldText}>{t('selectHealthInsurance')}</p>
          <Select
            onChange={handleChange}
            value={formik.values.healthPlan}
            valueArray={matches ? valueArrayMobile : valueArray}
            error={formik.touched.healthPlan && Boolean(formik.errors.healthPlan)}
            helperText={formik.touched.healthPlan && formik.errors.healthPlan}
            placeholder={t('selectOrType')}
          />
          {renderUnavailableError()}
        </div>
        <div className={commonStyles.wrapButton}>
          <Button label={t('back')} outlined onClick={onClickBack} />
          <Button label={t('next')} />
        </div>
      </form>
    </div>
  );
};

export default ChooseProviderForm;
