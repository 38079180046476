import { useFormik } from 'formik';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as yup from 'yup';
import { Button, MaskedInput } from '../../../../components';
import { appActions } from '../../../../store/app';
import { AppState } from '../../../../types/stores';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import placeholderCard from '../../../../assets/images/memberCards/placeholder.webp';
import Grid from '@mui/material/Grid';
import useMediaQuery from '@mui/material/useMediaQuery';
import commonStyles from '../../../../styles/common.module.scss';
import { healthPlans } from '../../../../helpers/constants';

interface Props {
  onClickBack: () => void;
  onClickNext: () => void;
  value: string;
}

const EnterMemderIDForm: React.FC<Props> = ({ onClickBack, onClickNext, value }) => {
  const matches = useMediaQuery('(max-width:576px)');
  const { healthPlan } = useSelector((state: AppState) => state);
  const { setFieldValue } = bindActionCreators(appActions, useDispatch());
  const { t } = useTranslation();

  const handleNext = () => {
    onClickNext();
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const formik = useFormik({
    validationSchema: yup.object({
      memberId: yup
        .string()
        .required(t('invalidmemberId'))
        .test('len', t('invalidmemberId'), (val?: string) =>
          healthPlan!.memberIdMaxLength! ? healthPlan!.memberIdMaxLength! === val?.length : true
        ),
    }),
    initialValues: {
      memberId: value || '',
    },
    onSubmit: onClickNext,
  });

  const onChange = ({ currentTarget }: React.FormEvent<HTMLInputElement>) => {
    const value = currentTarget.value.toUpperCase().replaceAll('_', '');

    formik.setFieldValue('memberId', value);
    setFieldValue(value, 'memberId');
  };

  const renderMemverCardExample = () => {
    const image = healthPlan?.cardExample ?? placeholderCard;

    return <img src={image} className={styles.wrapImg} />;
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container sx={{ justifyContent: 'center', padding: matches ? `0 4vw 4vh` : 0 }}>
        <Grid item xs={matches ? 12 : 7}>
          <p className={commonStyles.boldText}>{t('provideId')}</p>
          <MaskedInput
            isMonospace
            id="memberId"
            name="memberId"
            onChange={onChange}
            text={t('memberId')}
            value={formik.values.memberId}
            mask={healthPlan?.inputMask || ''}
            maskEnding={healthPlan?.maskEnding}
            placeholder={t('ex') + healthPlan?.placeholder}
            helperText={formik.touched.memberId && formik.errors.memberId}
            error={formik.touched.memberId && Boolean(formik.errors.memberId)}
            sx={{ width: '100%' }}
          />
        </Grid>
        <Grid item xs={matches ? 12 : 6}>
          <p className={styles.hintText}>
            {healthPlan?.name === healthPlans[3]?.name ? t('enterLastFiveDigits') : t('locateId')}
          </p>
          {renderMemverCardExample()}
        </Grid>
      </Grid>
      <div className={commonStyles.wrapButton}>
        <Button label={t('back')} outlined onClick={onClickBack} />
        <Button label={t('next')} onSubmit={handleNext} />
      </div>
    </form>
  );
};

export default EnterMemderIDForm;
