import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import colors from '../../styles/colors';
import { StepConnector, stepConnectorClasses } from '@mui/material';

const { stepperBackground, stepperLabel, white } = colors;

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    '& > *': {
      margin: `1vw`,
    },
  },
});
