import React from 'react';
import MUICheckbox from '@mui/material/Checkbox';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import { useStyles } from './styles';

interface Props {
  text?: string;
  value?: boolean;
  onChange?: any;
  id?: string;
  name?: string;
}

const Checkbox: React.FC<Props> = ({ value, onChange, id, name }) => {
  const { root } = useStyles();
  return (
    <div className={root}>
      <MUICheckbox
        id={id}
        name={name}
        checked={value}
        onChange={onChange}
        icon={<RadioButtonUncheckedIcon />}
        checkedIcon={<RadioButtonCheckedIcon />}
        inputProps={{ 'aria-label': 'Checkbox demo' }}
      />
    </div>
  );
};

export default Checkbox;
