import React from 'react';
import Input from '../Input';
import { Autocomplete } from '@mui/material';
import { useStyles, theme } from './styles';
import { IconSelect } from '../../assets/icons/IconSelect';
import { ThemeProvider } from '@mui/material/styles';
import { Typography } from '@mui/material';

interface Props {
  id: string;
  error?: any;
  value?: any;
  options: any;
  loading?: boolean;
  placeholder: string;
  handleChange?: (data: any) => void;
  helperText?: string | false | undefined;
  onInputChange?: (e: React.SyntheticEvent, value: string) => void;
}

const Search: React.FC<Props> = ({
  id,
  error,
  value,
  options,
  loading,
  helperText,
  placeholder,
  handleChange,
  onInputChange,
}) => {
  const { root } = useStyles();

  return (
    <ThemeProvider theme={theme}>
      <div className={root}>
        <Autocomplete
          id={id}
          value={value}
          options={options}
          loading={loading}
          sx={{ width: '100%' }}
          popupIcon={<IconSelect />}
          onInputChange={onInputChange}
          getOptionLabel={(value) => value || ''}
          onChange={(e: any, value) => handleChange?.(value)}
          isOptionEqualToValue={(option: any, value: any) => option === value}
          renderInput={(params) => <Input {...params} placeholder={placeholder} error={error} />}
        />
        <Typography>{helperText}</Typography>
      </div>
    </ThemeProvider>
  );
};

export default Search;
