import { makeStyles } from '@mui/styles';
import colors from '../../styles/colors';
import { createTheme } from '@mui/material/styles';

const { blue, white } = colors;
export const useStyles = makeStyles({
  root: {
    '& .MuiButton-root': {
      width: '8vw',
      minWidth: 90,
      padding: 18,
      borderRadius: 30,
      border: `1px solid ${blue}`,
      ['@media screen and (max-width: 576px)']: {
        width: '40vw',
      },
    },
    '& .MuiButton-contained': {
      backgroundColor: blue,
      color: white,
    },
    '& .MuiLoadingButton-root.MuiLoadingButton-loading': {
      backgroundColor: blue,
    },
  },
});

export const theme = createTheme({
  palette: {
    primary: {
      main: blue,
    },
    tonalOffset: 0,
    action: {
      hover: 'rgba(0, 0, 0, 0.05)',
    },
  },
});
