/* eslint-disable react/react-in-jsx-scope */
import SvgIcon from '@mui/material/SvgIcon';

export const IconSelect = (props: any) => (
  <SvgIcon
    {...props}
    width="20"
    height="11"
    viewBox="0 0 20 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 8.70358L1.27302 0.2125C0.97614 -0.0764008 0.50131 -0.0698996 0.21246 0.227C-0.0764004 0.5239 -0.0698905 0.9987 0.226979 1.2875L9.477 10.2875C9.7681 10.5708 10.2319 10.5708 10.523 10.2875L19.773 1.2875C20.0699 0.9987 20.0764 0.5239 19.7875 0.227C19.4987 -0.0698996 19.0239 -0.0764008 18.727 0.2125L10 8.70358Z"
      fill="#8AA3B9"
    />
  </SvgIcon>
);
