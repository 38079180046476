import React from 'react';
import { Select, Button, TimezoneOffset } from '../../../../components';
import { changeLanguage } from '../../../../i18n';
import styles from './styles.module.scss';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import LogoLanguage from '../../../../assets/icons/LogoLanguage.svg';
import { MenuItem } from '@mui/material';
import { Language } from '../../../../types';
import { useSelector } from 'react-redux';
import { AppState } from '../../../../types/stores';
import { langOptions } from '../../../../helpers/constants';
import commonStyles from '../../../../styles/common.module.scss';

interface Props {
  setValue: (value: any, fieldName: string) => void;
  onClickBack: () => void;
  onClickNext: () => void;
}

const ChooseLanguageForm: React.FC<Props> = ({ onClickNext, setValue }) => {
  const { t } = useTranslation();
  const { language } = useSelector((state: AppState) => state);

  const { setFieldValue, handleSubmit, values } = useFormik({
    initialValues: {
      lang: language?.name || '',
    },
    onSubmit: onClickNext,
  });

  const onChange = ({ code, name }: Language) => {
    changeLanguage(code);
    setFieldValue('lang', name);
    setValue({ code, name }, 'language');
  };

  const renderMenu = (value: Language, id: number) => (
    <MenuItem key={id} value={value as any}>
      {value.name}
    </MenuItem>
  );
  return (
    <div className={styles.wrap}>
      <form onSubmit={handleSubmit}>
        <div className={styles.wrapSelect}>
          <div className={styles.wrapLogoText}>
            <img src={LogoLanguage} alt="" className={styles.wrapImg} />
            <p className={commonStyles.boldText}>{t('selectLanguage')}</p>
          </div>
          <Select
            onChange={onChange}
            value={values.lang}
            valueArray={langOptions}
            placeholder={t('selectOrType')}
            renderCustomMenu={renderMenu}
          />
        </div>
        <div className={commonStyles.wrapButton}>
          <Button label={t('next')} />
        </div>
      </form>
    </div>
  );
};

export default ChooseLanguageForm;
