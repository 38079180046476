import { Dispatch } from 'redux';
import api from '../../api';
import requestsUrl from '../../api/requestsUrl';
import { LocationDetailsResponse, LocationInfo } from '../../types';
import { AppState } from '../../types/stores';
import { getSubmitRequestDataFromState } from '../../utils';
import store from '../store';
import {
  ActionTypes,
  CANCEL_SCHEDULING,
  CLICK_BACK,
  CLICK_NEXT,
  ESTIMATE_TRIP,
  ESTIMATE_TRIP_FAIL,
  ESTIMATE_TRIP_SUCCESS,
  PICK_LOCATION,
  PICK_LOCATION_FAIL,
  PICK_LOCATION_SUCCESS,
  REQUEST_TRIP,
  REQUEST_TRIP_FAIL,
  REQUEST_TRIP_SUCCESS,
  RESET_LOCATION,
  SEARCH_LOCATION,
  SEARCH_LOCATION_CLEAR,
  SEARCH_LOCATION_FAIL,
  SEARCH_LOCATION_SUCCESS,
  SELECT_PLAN,
  SET_FIELD_VALUE,
} from './types';

export const nextCall = () => (dispatch: Dispatch<ActionTypes>) => {
  dispatch({ type: CLICK_NEXT });
};

export const backCall = () => (dispatch: Dispatch<ActionTypes>) => {
  dispatch({ type: CLICK_BACK });
};

export const selectPlan = (value: number) => (dispatch: Dispatch<ActionTypes>) => {
  dispatch({ type: SELECT_PLAN, payload: value });
};

export const cancelScheduling = () => (dispatch: Dispatch<ActionTypes>) => {
  dispatch({ type: CANCEL_SCHEDULING });
};

export const setFieldValue =
  (value: any, fieldName: string) => (dispatch: Dispatch<ActionTypes>) => {
    dispatch({ type: SET_FIELD_VALUE, payload: value, fieldName });
  };

export const searchLocation = (input: string) => (dispatch: Dispatch<ActionTypes>) => {
  const safeInput = typeof input === 'string' && input.trim();
  if (safeInput) {
    dispatch({ type: SEARCH_LOCATION });

    api
      .post(requestsUrl.locationSearch, { autocomplete: { input: safeInput } })
      .then((response) => {
        if (response.data && response.data.length) {
          dispatch({ type: SEARCH_LOCATION_SUCCESS, payload: response.data });
        } else dispatch({ type: SEARCH_LOCATION_FAIL });
      })
      .catch(console.log);
  } else {
    dispatch({ type: SEARCH_LOCATION_CLEAR });
  }
};

export const submitRequest = (appState: AppState) => (dispatch: Dispatch<ActionTypes>) => {
  const request = getSubmitRequestDataFromState(appState);

  dispatch({ type: REQUEST_TRIP });
  api
    .post(requestsUrl.tripRequest, { request })
    .then(({ data }) => {
      dispatch({
        type: REQUEST_TRIP_SUCCESS,
        referenceNumberId: data.reference_number_id,
      });
    })
    .catch(() => {
      dispatch({ type: REQUEST_TRIP_FAIL });
      window.alert('Something went wrong');
    });
};

export const resetLocation = (locationType: string) => (dispatch: Dispatch<ActionTypes>) =>
  dispatch({ type: RESET_LOCATION, locationType });

export const pickLocation =
  (place_id: string, locationType: string) => (dispatch: Dispatch<ActionTypes>) => {
    dispatch({ type: PICK_LOCATION, locationType });
    api
      .post(requestsUrl.locationDetails, { details: { place_id } })
      .then(({ data }) =>
        dispatch({
          type: PICK_LOCATION_SUCCESS,
          payload: data as LocationDetailsResponse,
          locationType,
        })
      )
      .catch(() => dispatch({ type: PICK_LOCATION_FAIL, locationType }));
  };

export const estimateTrip =
  (appointmentTime: string, pickUp: LocationInfo, dropOff: LocationInfo) =>
  (dispatch: Dispatch<ActionTypes>) => {
    dispatch({ type: ESTIMATE_TRIP });

    api
      .post(requestsUrl.tripEstimate, {
        estimate: {
          pickup: {
            address1: pickUp.address1,
            city: pickUp.city,
            state: pickUp.state,
            postal_code: pickUp.zipCode,
          },
          dropoff: {
            address1: dropOff.address1,
            city: dropOff.city,
            state: dropOff.state,
            postal_code: dropOff.zipCode,
          },
          appointment_time: appointmentTime,
        },
      })
      .then(({ data }) => dispatch({ type: ESTIMATE_TRIP_SUCCESS, payload: data }))
      .catch((err) => {
        dispatch({ type: ESTIMATE_TRIP_FAIL });
        console.log(err);
      });
  };
