export const en = {
  translation: {
    selectLanguage: 'Please select your language',
    scheduleYourRide: 'Let’s Schedule your ride!',
    importantNote: 'If this is a medical emergency, please call 911 immediately',
    ifYouAppointment: 'If your appointment is less than 48 hours away, please schedule via phone ',
    selectHealthInsurance: 'Select your health insurance',
    selectPurpose: 'Please select the purpose of your visit',
    provideContactDetails: 'Please provide your contact details',
    provideDropOffTime: `Please provide the date and time for when you would like to arrive at your appointment`,
    providePickUpAddress: 'Please enter your pickup address',
    provideDropOffAddress: 'Please enter your destination address',
    provideId: 'Please provide your member ID',
    provideDetails: 'Please provide your member details',
    appointmentHint:
      'If your appointment is less than 48 hours away, please schedule via phone 844-743-4344',
    next: 'Next',
    back: 'Back',
    locateId: 'Locate your member ID on insurance card',
    selectOrType: 'Type here or select one',
    phoneNumber: 'Phone Number',
    landline: 'Landline',
    mobile: 'Mobile',
    email: 'Email',
    additionalConfirmationEmail: 'Additional Confirmation Email Recipient',
    memberId: 'Member ID',
    firstName: 'First Name',
    secondName: 'Last Name',
    birthDate: 'Date of birth',
    start: 'Start',
    date: 'Date',
    time: 'Time',
    timezone: 'Destination Time Zone',
    pleaseEnterDate: 'Please enter a date',
    enterValidDate: 'Enter a valid date',
    pleaseEnterTime: 'Please enter time',
    enterValidNumber: 'Enter a valid phone number',
    enterValidEmail: 'Please enter a valid email',
    pleaseAddPurposeVisit: 'Please select purpose',
    pleaseChooseLocation: 'Please choose location',
    pleaseEnterAdress: 'Please enter address',
    pleaseEnterDestinationAddress: 'Please enter your destination address',
    pleaseEnterCity: 'Please enter city',
    pleaseEnterState: 'Please enter state',
    pleaseEnterZipCode: 'Please enter zip code',
    pleaseAddFirstName: 'Please add first name',
    pleaseAddLastName: 'Please add last name',
    invalidmemberId: 'Invalid Member ID',
    yourFirstName: 'Your First Name',
    yourSecondName: 'Your Last Name',
    searchForAddress: 'Search for Address',
    inputAddressHere: 'Type address here',
    addressLine1: 'Address line 1',
    addressLine2: 'Address line 2',
    addressLine2Placeholder: 'Apartment, building, unit, etc.',
    city: 'City',
    state: 'State',
    zip: 'Zip Code',
    textHome: 'Address Name',
    placeholderHome: 'ex. Home',
    placeholderHospital: 'ex. Hospital',
    healthInsuarance: 'Health insurance',
    ex: 'ex.',
    ext: 'Ext.',
    cancel: 'Cancel',
    optional: '(Optional)',
    exOptional: 'Ext.',
    primaryCompanionName: 'Primary Companion Name',
    relationship: 'Relationship',
    contactNumber: 'Contact Number',
    requireAssistance: 'Do you require assistance or use any devices?',
    requireAssistanceHelp:
      "If you're requesting Mileage Reimbursement, select Mileage Reimbursement below",
    selectTripType: 'One way or round trip?',
    roundTrip: 'Round trip',
    roundTripPickUpTime: 'What time would you like to be picked up from your appointment?',
    oneWay: 'One way',
    willCall: 'Will call',
    willCallHint1: 'Choose “will call” if you would like to call when ready.',
    willCallHint2: 'Note: Wait times are dependent on provider availability.',
    deviceEquipment: 'Devices & Equipment',
    numberOfCompanions: 'Number of companions',
    none: 'None',
    one: 'One',
    two: 'Two',
    verifyTripDetails: 'Verify Trip Details',
    submit: 'Submit',
    insuranceCompany: 'Insurance company',
    insuranceId: 'Insurance ID',
    companions: 'Companions',
    name: 'Name',
    member: 'Member',
    memberInfo: 'Member information',
    trip: 'Trip',
    visitFor: 'Visit for',
    assistance: 'Assistance',
    cancelWarning: 'All entered data will be lost if you proceed.',
    suggestedTime:
      'Based on the appointment arrival time you requested, your driver will be scheduled to pick you up on',
    unavailablePlan: `Unfortunately, CareCar does not currently work with your health insurance plan. 
      Please contact your health insurance company.`,
    oneWayTrip: 'One way trip',
    copy: 'Copy',
    print: 'Print',
    tripDetails: 'Trip Details',
    pickup: 'Pickup',
    dropoff: 'Drop off',
    returnTrip: 'Return trip',
    youChooseWillCall: `You chose 'will call'`,
    noteWaitingTime: `Note: Wait time depends on provider's availability`,
    basedOnADistanceOf: 'Based on a distance of',
    withATravelTimeOf: 'With a travel time of',
    forAnAppointmentOf: 'For an appointment arrival time of',
    miles: 'miles',
    minutes: 'minutes',
    firstTrip: 'First trip',
    pleaseSelectOne: 'Please select one',
    fieldCantBeEmpty: 'This field cannot be empty',
    enterPickupTime: 'Please enter pick up time',
    returnPickupTime: 'Return pick up time you selected',
    enterValidData2: 'Please entere a valid value. This field is incomplete or has an invalid date',
    successText: 'Your request has been succesfully submitted. ',
    succesText2: ' Please save this information for you records.',
    notes: 'Notes',
    notesPlaceholder:
      "Gate code is 1234 / Building #2 / I'll need some help loading my walker into the car / Meet you right out-front the address I've provided, next to the mailbox / I've got on a blue hat / Also, it's on the North side of street",
    enterManualAddress: 'Click here to manually enter address',
    companionAge: 'Companions must be over 18 years old',
    other: 'Other',
    note2Min: 'Note: Takes approximately 2 min',
    pleaseSpecify: 'Please specify',
    placeholderSpecify: 'Gym, Grocery Store, etc.',
    notesTip: 'Notes help us provide the highest level of service possible',
    referenceNumber: 'Your reference number',
    phoneNumberTip:
      'If you’re scheduling on behalf of somebody else, please enter the best number to reach the member on the day of the appointment',
    enterLastFiveDigits: 'Please enter the last 5 digits',
  },
};

export default en;
