/* eslint-disable no-case-declarations */
import { defaultTimezone, healthPlans, langOptions } from '../../helpers/constants';
import { AppState, RequestState } from '../../types/stores';
import {
  ActionTypes,
  CLICK_NEXT,
  CLICK_BACK,
  SELECT_PLAN,
  SET_FIELD_VALUE,
  CANCEL_SCHEDULING,
  SEARCH_LOCATION_SUCCESS,
  SEARCH_LOCATION,
  SEARCH_LOCATION_CLEAR,
  SEARCH_LOCATION_FAIL,
  PICK_LOCATION,
  PICK_LOCATION_FAIL,
  PICK_LOCATION_SUCCESS,
  RESET_LOCATION,
  ESTIMATE_TRIP_SUCCESS,
  ESTIMATE_TRIP_FAIL,
  REQUEST_TRIP_SUCCESS,
  REQUEST_TRIP_FAIL,
  REQUEST_TRIP,
  ESTIMATE_TRIP,
} from './types';

const defaultLocation = {
  searchInput: '',
  address1: '',
  address2: '',
  city: '',
  state: '',
  zipCode: '',
  addressName: '',
  contactNumber: '',
  contactNumberExt: '',
};

const initState: AppState = {
  step: 0,
  memberId: '',
  language: langOptions[0],
  healthPlan: undefined,
  purposeVisit: { alias: '', value: '' },
  firstName: '',
  secondName: '',
  birthDate: '',
  phoneNumber: '',
  isMobile: true,
  email: '',
  additionalConfirmationEmail: '',
  searchLocationAutocompleteOptions: [],
  searchLoading: false,
  pickLocationStatus: RequestState.INIT,
  pickUpLocation: defaultLocation,
  dropOffLocation: defaultLocation,
  dropOffDate: '',
  timezone: defaultTimezone,
  dropOffTime: '',
  assistanceInfo: {},
  roundTripInfo: {
    isOneWay: false,
    willCall: false,
    pickUpTime: undefined,
  },
  tripEstimation: undefined,
  specifyOtherPurpose: '',
  appointmentTime: '',
  referenceId: '',
  submitLoading: false,
  estimationLoading: false,
};

const appReducer = (state = initState, action: ActionTypes) => {
  switch (action.type) {
    case CLICK_NEXT:
      return {
        ...state,
        step: state.step + 1,
      };
    case CLICK_BACK:
      return {
        ...state,
        step: state.step - 1,
      };
    case SELECT_PLAN:
      return {
        ...state,
        memberId: '',
        healthPlan: healthPlans[action.payload],
      };
    case SEARCH_LOCATION:
      return {
        ...state,
        searchLoading: true,
      };
    case SEARCH_LOCATION_CLEAR:
      return {
        ...state,
        searchLoading: false,
      };
    case SEARCH_LOCATION_SUCCESS:
      return {
        ...state,
        searchLocationAutocompleteOptions: action.payload,
        searchLoading: false,
      };
    case REQUEST_TRIP_SUCCESS:
      return {
        ...state,
        referenceId: action.referenceNumberId,
        submitLoading: false,
      };
    case SEARCH_LOCATION_FAIL:
      return {
        ...state,
        searchLoading: false,
      };
    case RESET_LOCATION:
      return {
        ...state,
        [action.locationType]: defaultLocation,
      };
    case PICK_LOCATION:
      return {
        ...state,
        pickLocationStatus: RequestState.LOADING,
      };
    case PICK_LOCATION_SUCCESS:
      const {
        city,
        name,
        address1,
        address2,
        latitude,
        longitude,
        postal_code,
        phone_number,
        state: countryState,
      } = action.payload;

      return {
        ...state,
        [action.locationType]: {
          ...state[action.locationType],
          city,
          address1,
          address2,
          latitude,
          longitude,
          addressName: name,
          state: countryState,
          zipCode: postal_code,
          locationPhoneNumber: phone_number,
        },
        pickLocationStatus: RequestState.DONE,
      };
    case PICK_LOCATION_FAIL:
      return {
        ...state,
        pickLocationStatus: RequestState.FAILED,
      };

    case CANCEL_SCHEDULING:
      return {
        ...initState,
      };
    case ESTIMATE_TRIP:
      return {
        ...state,
        estimationLoading: true,
      };
    case ESTIMATE_TRIP_SUCCESS:
      return {
        ...state,
        tripEstimation: action.payload,
        estimationLoading: false,
      };
    case ESTIMATE_TRIP_FAIL:
      return {
        ...state,
        estimationLoading: false,
        tripEstimation: undefined,
      };
    case SET_FIELD_VALUE:
      return {
        ...state,
        [action.fieldName]: action.payload,
      };
    case REQUEST_TRIP:
      return {
        ...state,
        submitLoading: true,
      };
    case REQUEST_TRIP_FAIL:
      return {
        ...state,
        submitLoading: false,
      };
    default:
      return state;
  }
};
export default appReducer;
