import {
  HealthPlan,
  Language,
  AssistanceInfo,
  RoundTripInfo,
  LocationInfo,
  AutocompleteOption,
  TripEstimation,
  PurposeItem,
  Timezone,
} from '..';

export enum RequestState {
  INIT,
  LOADING,
  DONE,
  FAILED,
}

export interface AppState {
  step: number;
  language?: Language;
  memberId: string;
  healthPlan?: HealthPlan;
  purposeVisit: PurposeItem;
  firstName: string;
  secondName: string;
  birthDate: string;
  phoneNumber: string;
  isMobile: boolean;
  email: string;
  additionalConfirmationEmail: string
  searchLoading: boolean;
  pickLocationStatus: RequestState;
  searchLocationAutocompleteOptions: AutocompleteOption[];
  pickUpLocation: LocationInfo;
  dropOffLocation: LocationInfo;
  dropOffDate: string;
  timezone: Timezone;
  dropOffTime: string;
  assistanceInfo: AssistanceInfo;
  roundTripInfo: RoundTripInfo;
  tripEstimation?: TripEstimation;
  specifyOtherPurpose: string;
  appointmentTime: string | Date;
  referenceId: string;
  submitLoading: boolean;
  estimationLoading: boolean;
}
