import { makeStyles } from '@mui/styles';
import colors from '../../styles/colors';

const { darkGrey, blue } = colors;

export const useStyles = makeStyles({
  root: {
    '& .MuiCheckbox-root': {
      color: darkGrey,
    },
    '& .MuiCheckbox-root.Mui-checked': {
      color: blue,
    },
  },
});
