import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

import { es } from './i18n/translation/es';
import { en } from './i18n/translation/en';
import { yue } from './i18n/translation/yue';
import { ko } from './i18n/translation/ko';
import { cmn } from './i18n/translation/cmn';
import { vi } from './i18n/translation/vi';

const translations = { es, en, yue, ko, cmn, vi };

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    debug: true,
    resources: translations,
    react: {
      useSuspense: false,
    },
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;

export const changeLanguage = (language: string) => i18n.changeLanguage(language);
