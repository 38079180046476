/* eslint-disable import/no-extraneous-dependencies */
import React, { useRef, useState, useEffect } from 'react';
import { Button } from '../../../../components';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import commonStyles from '../../../../styles/common.module.scss';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../../types/stores';
import { Typography } from '@mui/material';
import TripIcon from '../../../../assets/icons/trip.svg';
import RoundTripIcon from '../../../../assets/icons/round_trip.svg';
import { LocationInfo } from '../../../../types';
import tipIcon from '../../../../assets/icons/tip.svg';
import {
  getFormattedPhoneNumber,
  metersToMiles,
  secondsToMinutes,
  getReturnPickUpTime,
} from '../../../../utils';
import moment from 'moment';
import { dateFormats } from '../../../../helpers/constants';
import ReactTooltip from 'react-tooltip';
import { bindActionCreators } from 'redux';
import { appActions } from '../../../../store/app';
import { getDateTimeZoneOffset } from '../../../../helpers/datetime';

type ColumnSectionInfo = { title: string; value?: string | number };

interface Props {
  onClickBack: () => void;
}

const VerifyForm: React.FC<Props> = ({ onClickBack }) => {
  const [isPrinting, setIsPrinting] = useState(false);
  const printRef = useRef(null);
  const [returnTimeTipVisible, setReturnTimeTipVisible] = useState(false);
  const [pickUpTimeTipVisible, setPickUpTTimeTipVisible] = useState(false);
  const { t } = useTranslation();
  const matches = useMediaQuery('(max-width: 576px)');
  const {
    firstName,
    secondName,
    birthDate,
    phoneNumber,
    email,
    additionalConfirmationEmail,
    healthPlan,
    memberId,
    purposeVisit,
    pickUpLocation,
    dropOffLocation,
    dropOffDate,
    dropOffTime,
    timezone,
    tripEstimation,
    appointmentTime,
    assistanceInfo: { assistance, companionName, numberOfCompanions, companionPhoneNumber },
    roundTripInfo: { isOneWay, willCall, pickUpTime },
    referenceId,
    submitLoading,
  } = useSelector((state: AppState) => state);
  const { submitRequest } = bindActionCreators(appActions, useDispatch());
  const formTitle = referenceId ? 'tripDetails' : 'verifyTripDetails';
  const rightButtonTitle = referenceId ? 'print' : 'submit';
  const requestData = useSelector((state: AppState) => state);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [referenceId]);

  const memberInfo: ColumnSectionInfo[] = [
    { title: t('birthDate'), value: birthDate },
    { title: t('phoneNumber'), value: getFormattedPhoneNumber(phoneNumber) },
    { title: 'Email', value: email },
    { title: 'Additional Confirmation Email Recipient', value: additionalConfirmationEmail },
  ];


  const healthProviderInfo: ColumnSectionInfo[] = [
    { title: t('healthInsuarance'), value: healthPlan?.name },
    { title: t('insuranceId'), value: memberId },
  ];
  const assistanceInfo: ColumnSectionInfo[] = [
    { title: t('deviceEquipment'), value: assistance?.value },
    { title: t('companions'), value: numberOfCompanions || '' },
    { title: t('name'), value: companionName || '' },
    {
      title: t('phoneNumber'),
      value: getFormattedPhoneNumber(companionPhoneNumber!),
    },
  ];

  const onPressSubmit = () => submitRequest(requestData);

  const onBeforePrint = async () => {
    setIsPrinting(true);
  };

  const onPressPrint = () => onBeforePrint().then(window.print);

  window.onafterprint = () => {
    setIsPrinting(false);
  };

  const onPressLeftButton = () => onClickBack();
  const onPressRightButton = () => (referenceId ? onPressPrint : onPressSubmit)();

  const renderDivider = () => <div className={styles.divider} />;

  const renderTripTipContent = () => (
    <div>
      <div className={styles.row}>
        <p className={styles.tipContentText}>{t('basedOnADistanceOf')}&nbsp; </p>
        <p className={styles.tipContentTextBold}>
          {metersToMiles(tripEstimation?.distance)} {t('miles')}
        </p>
      </div>
      <div className={styles.row}>
        <p className={styles.tipContentText}>{t('withATravelTimeOf')}&nbsp; </p>
        <p className={styles.tipContentTextBold}>
          {secondsToMinutes(tripEstimation?.estimate)} {t('minutes')}
        </p>
      </div>
      <div className={styles.row}>
        <p className={styles.tipContentText}>{t('forAnAppointmentOf')}&nbsp; </p>
        <p className={styles.tipContentTextBold}>
          {moment(new Date(`${dropOffDate} ${dropOffTime}`)).format('hh:mm A')} {timezone.code}
        </p>
      </div>
    </div>
  );

  const renderReturnPickUpTimeTipContent = () => (
    <div>
      <p className={styles.returnTipText}>{t('returnPickupTime')}</p>
    </div>
  );

  const estimatePickupTime = appointmentTime ?? '';
  const estimateUtcOffset = getDateTimeZoneOffset(new Date(estimatePickupTime), timezone);

  const renderReturnPickUpTime = (isVisible: boolean) =>
    isVisible && pickUpTime ? (
      <div className={styles.suggestedTimeContainer}>
        <Typography className={styles.locationName}>
          &nbsp;
          {t('time')}:{' '}
          {moment(getReturnPickUpTime(appointmentTime, pickUpTime)).format(
            dateFormats.suggestedTripTime
          )}{' '}
          {timezone.code},{' '}
          {moment(getReturnPickUpTime(appointmentTime, pickUpTime)).format(
            dateFormats.suggestedTripDate
          )}
          &nbsp;
        </Typography>
        <img
          src={tipIcon}
          data-tip="tooltip"
          data-type="light"
          data-class={styles.tipContainer}
          onMouseEnter={() => setReturnTimeTipVisible(true)}
          onMouseLeave={() => setReturnTimeTipVisible(false)}
        />
        {returnTimeTipVisible && (
          <ReactTooltip className={styles.tooltip} getContent={renderReturnPickUpTimeTipContent} />
        )}
      </div>
    ) : undefined;

  const renderInfoColumns = (info: ColumnSectionInfo[]) => (
    <div className={styles.infoRow}>
      {info.map(
        ({ title, value }) =>
          value && (
            <div key={value} className={styles.memberInfoColumn}>
              <Typography className={styles.memberInfoTitle} fontSize={12}>
                {title}
              </Typography>
              <Typography className={styles.memberInfoValue}>{value}</Typography>
            </div>
          )
      )}
    </div>
  );

  const renderLocationInfo = (
    location: LocationInfo,
    isDropOff?: boolean,
    withSuggestedTime?: boolean,
    returnPickUp?: JSX.Element
  ) => {
    const { address1, city, zipCode } = location;
    const title = isDropOff ? t('dropoff') : t('pickup');

    return (
      <div>
        {willCall && !isOneWay && !withSuggestedTime && !isDropOff && (
          <>
            <Typography className={styles.locationName}>{t('youChooseWillCall')}</Typography>
            <Typography className={styles.suggestedTimeText}>{t('noteWaitingTime')}</Typography>
          </>
        )}
        <div className={styles.locationRow}>
          <Typography className={styles.locationName}>{title}</Typography>
          {withSuggestedTime && (
            <div className={styles.row}>
              <Typography className={styles.locationName}>
                &nbsp;
                {t('time')}:{' '}
                {tripEstimation?.pickup_time &&
                  moment(tripEstimation!.pickup_time)
                    .utcOffset(estimateUtcOffset)
                    .format(dateFormats.suggestedTripTime)}{' '}
                {timezone.code},{' '}
                {tripEstimation?.pickup_time &&
                  moment(tripEstimation!.pickup_time)
                    .utcOffset(estimateUtcOffset)
                    .format(dateFormats.suggestedTripDate)}
              </Typography>

              <img
                src={tipIcon}
                data-tip="tooltip"
                data-type="light"
                data-class={styles.tipContainer}
                onMouseEnter={() => setPickUpTTimeTipVisible(true)}
                onMouseLeave={() => setPickUpTTimeTipVisible(false)}
              />
              {pickUpTimeTipVisible && (
                <ReactTooltip className={styles.tooltip} getContent={renderTripTipContent} />
              )}
            </div>
          )}
          {returnPickUp}
        </div>
        {withSuggestedTime}
        <Typography className={styles.regularText}>
          {address1}, {city}, {zipCode}
        </Typography>
      </div>
    );
  };

  const renderMemberSection = () => (
    <div>
      <Typography fontWeight={700} className={styles.blueTitle} fontSize={16}>
        {t('member')}
      </Typography>
      <Typography className={styles.fullName} fontSize={20}>
        {`${firstName} ${secondName}`}
      </Typography>
      <Typography className={styles.greyBoldText} fontSize={16}>
        {t('memberInfo')}
      </Typography>
      {!matches && renderDivider()}
      {renderInfoColumns(memberInfo)}
      {!matches && renderDivider()}
      {renderInfoColumns(healthProviderInfo)}
    </div>
  );

  const renderTripSection = () => (
    <div className={styles.sectionContainer}>
      <Typography className={styles.blueTitle} fontSize={16}>
        {t(isOneWay ? 'oneWayTrip' : 'roundTrip')}
      </Typography>
      <Typography className={styles.purposeText}>{dropOffDate}</Typography>
      {!isOneWay && <Typography className={styles.blueTitle}>{t('firstTrip')}</Typography>}
      <div className={styles.tripSection}>
        <img src={RoundTripIcon} className={styles.tripIcon}></img>
        <div className={styles.locationsContainer}>
          {renderLocationInfo(pickUpLocation, undefined, true)}
          {renderLocationInfo(dropOffLocation, true)}
        </div>
      </div>
      {!isOneWay && (
        <>
          <Typography className={styles.blueTitle}>{t('returnTrip')}</Typography>
          <div className={styles.tripSection}>
            <img src={willCall ? RoundTripIcon : TripIcon} className={styles.tripIcon}></img>
            <div className={styles.locationsContainer}>
              {renderLocationInfo(
                dropOffLocation,
                undefined,
                undefined,
                renderReturnPickUpTime(true)
              )}
              {renderLocationInfo(pickUpLocation, true, undefined)}
            </div>
          </div>
        </>
      )}
    </div>
  );

  const renderPurposeSection = () => (
    <div className={styles.sectionContainer}>
      {((numberOfCompanions && parseInt(numberOfCompanions) > 0) || assistance) && (
        <>
          <Typography className={styles.greyBoldText} fontSize={16}>
            {t('assistance')}
          </Typography>
          {!matches && renderDivider()}
          {renderInfoColumns(assistanceInfo)}
        </>
      )}
    </div>
  );

  const renderHandleButtons = () =>
    !isPrinting && (
      <div className={`${commonStyles.wrapButton} ${referenceId ? styles.buttonsContainer : ''}`}>
        <div className={styles.buttonsRow}>
          {!referenceId && <Button label={t('back')} outlined onClick={onPressLeftButton} />}
          <Button
            label={t(rightButtonTitle)}
            onClick={onPressRightButton}
            submitLoading={submitLoading}
          />
        </div>
      </div>
    );

  const renderReferenceNumber = () =>
    referenceId && (
      <p className={styles.referenceIdStyle}>
        {t('referenceNumber')}: <span>{referenceId}</span>
      </p>
    );

  const renderSuccessMessage = () =>
    referenceId &&
    !isPrinting && (
      <div>
        <h2 className={styles.noteText}>{t('successText')}</h2>
        <h2 className={styles.noteText2}>{t('succesText2')}</h2>
      </div>
    );
    
  const renderConsentBox = () => (
    <div className={styles.consentBox}>
      <Typography className={styles.consentText}>
        By submitting a ride request with your contact information, you consent to receive important
        updates and reminders about your service via email, text, or phone call. Message and data
        rates may apply. To opt out of text messages, reply STOP at any time. For other communications,
        follow the opt-out instructions included in our messages.{' '}
        <a href="https://carecar.co/privacy" target="_blank" rel="noopener noreferrer">
          Privacy Policy
        </a>
        .{' '}
        <a href="https://carecar.co/terms" target="_blank" rel="noopener noreferrer">
          Terms
        </a>
        .
      </Typography>
    </div>
  );
    

  return (
    <div className={styles.container}>
      <div ref={printRef} className={styles.wrap}>
        {renderSuccessMessage()}
        {renderReferenceNumber()}
        <p className={`${commonStyles.boldText} ${styles.title}`}>{t(formTitle)}</p>
        {renderMemberSection()}
        <Typography className={styles.purposeText}>{purposeVisit.value}</Typography>
        {renderTripSection()}
        {renderPurposeSection()}
      </div>
      {renderConsentBox()}
      {renderHandleButtons()}
    </div>
  );
};

export default VerifyForm;
