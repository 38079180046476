import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export const LeftArrowButton = (props: any) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.82503 1.175L5.65003 0L0.650024 5L5.65003 10L6.82503 8.825L3.00836 5L6.82503 1.175Z"
      fill="#3D5E7B"
    />
  </SvgIcon>
);
