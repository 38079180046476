import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import colors from '../../styles/colors';
import { StepConnector, stepConnectorClasses } from '@mui/material';

const { stepperBackground, stepperLabel, white } = colors;

export const useStyles = makeStyles({
  root: {
    width: '100vw',
    height: '16vh',
    marginBottom: '2vh',
    '& .MuiStepper-root': {
      height: '8vh',
      paddingLeft: '10vw',
      paddingRight: '10vw',
      paddingBottom: '3vh',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: stepperBackground,
      ['@media screen and (max-width: 576px)']: {
        height: '9vh',
      },
    },
    '& .MuiTypography-root': {
      fontWeight: 'bold',
      color: stepperBackground,
    },
    '& .css-nnsfc0-MuiTypography-root': {
      color: colors.black,
      fontFamily: 'Mulish',
      fontSize: 27,
    },
    '& .MuiStep-root': {
      ['@media screen and (max-width: 576px)']: {
        padding: 0,
      },
    },
  },
  cancelContainer: {
    backgroundColor: stepperBackground,
    marginBottom: -3,
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: '3vw',
    paddingTop: '2vh',
  },
});

export const ColorlibStepIconRoot = styled('div')<{
  ownerState: { completed?: boolean; active?: boolean };
}>(({ ownerState }) => ({
  zIndex: 1,
  color: colors.white,
  width: 24,
  height: 24,
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: stepperLabel,
  ...(ownerState.active && {
    backgroundColor: white,
  }),
  ...(ownerState.completed && {
    backgroundColor: white,
  }),
  ['@media screen and (max-width: 576px)']: {
    height: '8vw',
    width: '8vw',
  },
}));

export const ColorlibSubStepIconRoot = styled('div')<{
  ownerState: { completed?: boolean; active?: boolean };
}>(({ ownerState }) => ({
  zIndex: 1,
  color: colors.white,
  width: 10,
  height: 10,
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: stepperLabel,
  ...(ownerState.active && {
    backgroundColor: white,
  }),
  ...(ownerState.completed && {
    backgroundColor: white,
  }),
  ['@media screen and (max-width: 576px)']: {
    height: '1.5vh',
    width: '1.5vw',
  },
}));

export const ColorlibConnector = styled(StepConnector)({
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: white,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: white,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 1,
    border: 0,
    backgroundColor: stepperLabel,
    marginLeft: -20,
    marginRight: -20,
    borderRadius: 1,
  },
});
