import React from 'react';
import * as yup from 'yup';
import { Select, Button, Checkbox, Input, MaskedInput } from '../../../../components';
import styles from './styles.module.scss';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useSelector } from 'react-redux';
import { AppState } from '../../../../types/stores';
import { Grid } from '@mui/material';
import { validateString } from '../../../../utils';
import i18n from 'i18next';
import { AssistanceItem, LanguageOption } from '../../../../types/index';
import tipIcon from '../../../../assets/icons/tip.svg';
import { assistancesTranslation } from '../../../../i18n/translationAssistances';
import commonStyles from '../../../../styles/common.module.scss';
import ReactTooltip from 'react-tooltip';

interface Props {
  onClick: (value: any, fieldName: string) => void;
  onClickBack: () => void;
  onClickNext: () => void;
}

const AssistanceForm: React.FC<Props> = ({ onClick, onClickBack, onClickNext }) => {
  const {
    assistance,
    companionName,
    numberOfCompanions,
    companionPhoneNumber,
    companionRelationship,
    isCompanionPhoneNumberMobile,
  } = useSelector((state: AppState) => state.assistanceInfo);
  const { t } = useTranslation();
  const matches = useMediaQuery('(max-width:576px)');
  const companionsOptions = [t('none'), t('one'), t('two')];
  const assistanceOptions = assistancesTranslation[i18n.language as LanguageOption];
  const valueArray = assistanceOptions.map(({ value }: AssistanceItem) => value);

  const { values, handleSubmit, handleBlur, touched, handleChange, errors, setFieldValue } =
    useFormik({
      validationSchema: yup.object({
        assistance: yup.string(),
        numberOfCompanions: yup.number(),
        companionPhoneNumber: yup
          .string()
          .min(10, t('enterValidNumber'))
          .when('numberOfCompanions', {
            is: () => !!values.companionPhoneNumber,
            then: yup.string().phone('US', true, t('enterValidNumber')),
          }),
      }),
      initialValues: {
        assistance: assistance?.value || valueArray[0],
        companionName: companionName || '',
        companionPhoneNumber: companionPhoneNumber || '',
        numberOfCompanions: numberOfCompanions || 0,
        companionRelationship: companionRelationship || '',
        isCompanionPhoneNumberMobile: !isCompanionPhoneNumberMobile,
      },
      onSubmit: () => {
        onClick(
          {
            ...values,
            assistance: assistanceOptions.find(
              ({ value }: AssistanceItem) => value === values.assistance
            ),
          },
          'assistanceInfo'
        );
        onClickNext();
      },
    });

  const onPressCheckBox = (index: number) => {
    if (index === 0) {
      setFieldValue('companionName', '');
      setFieldValue('companionPhoneNumber', '');
    }

    setFieldValue('numberOfCompanions', index);
  };

  const renderSingleCheckbox = (value: string, index: number) => (
    <div key={value} className={commonStyles.checkbox}>
      <Checkbox
        value={values.numberOfCompanions === index}
        onChange={() => onPressCheckBox(index)}
      />
      <p>{value}</p>
    </div>
  );

  const renderCompanionsSelect = () => (
    <>
      <div className={styles.row}>
        <p className={styles.headerCheckbox}>{t('numberOfCompanions')}</p>
        {renderTip()}
      </div>
      <div className={`${commonStyles.wrapCheckbox} ${styles.wrapCheckbox}`}>
        {companionsOptions.map(renderSingleCheckbox)}
      </div>
    </>
  );

  const renderTipContent = () => (
    <div>
      <p className={styles.tipContentTimeText}>{t('companionAge')}</p>
    </div>
  );

  const renderTip = () => (
    <>
      <ReactTooltip getContent={renderTipContent} />
      <img src={tipIcon} data-tip="tooltip" data-type="light" data-class={styles.tipContainer} />
    </>
  );

  const renderCompanionInfoInputs = () =>
    values.numberOfCompanions ? (
      <Grid container spacing={4}>
        <Grid item xs={matches ? 12 : 7}>
          <Input
            isOptional
            id="companionName"
            name="companionName"
            sx={{ width: '100%' }}
            onChange={handleChange}
            value={values.companionName}
            text={t('primaryCompanionName')}
            error={touched.companionName && Boolean(errors.companionName)}
            helperText={touched.companionName && errors.companionName}
          />
        </Grid>
        <Grid item xs={matches ? 12 : 5}>
          <Input
            text={t('relationship')}
            isOptional
            sx={{ width: '100%' }}
            onChange={handleChange}
            id="companionRelationship"
            name="companionRelationship"
            value={values.companionRelationship}
          />
        </Grid>
        <Grid item xs={matches ? 12 : 7}>
          <MaskedInput
            text={t('contactNumber')}
            isOptional
            placeholder="(555) 000-0000"
            id="companionPhoneNumber"
            name="companionPhoneNumber"
            mask="(999) 999-9999"
            sx={{ width: '100%' }}
            value={values.companionPhoneNumber}
            onBlur={handleBlur}
            onChange={(e: any) => {
              const value = e.target.value || '';
              setFieldValue('companionPhoneNumber', validateString(value));
            }}
            error={touched.companionPhoneNumber && Boolean(errors.companionPhoneNumber)}
            helperText={touched.companionPhoneNumber && errors.companionPhoneNumber}
          />
        </Grid>
        <Grid item xs={matches ? 9 : 5} sx={{ marginTop: matches ? 0 : 4 }}>
          <div className={styles.wrapPhoneCheckbox}>
            <div className={styles.checkbox}>
              <Checkbox
                value={values.isCompanionPhoneNumberMobile}
                onChange={() => setFieldValue('isCompanionPhoneNumberMobile', true)}
              />
              <p>{t('mobile')}</p>
            </div>
            <div className={styles.checkbox}>
              <Checkbox
                value={!values.isCompanionPhoneNumberMobile}
                onChange={() => setFieldValue('isCompanionPhoneNumberMobile', false)}
              />
              <p>{t('landline')}</p>
            </div>
          </div>
        </Grid>
      </Grid>
    ) : null;

  return (
    <div className={styles.wrap}>
      <form onSubmit={handleSubmit}>
        <div className={styles.wrapConatiner}>
          <p className={commonStyles.boldText}>
            <b>{t('requireAssistance')}</b>
          </p>
          <p>{t('requireAssistanceHelp')}</p>
          <Select
            id="assistance"
            valueArray={valueArray}
            value={values.assistance}
            text={t('deviceEquipment')}
            placeholder={t('selectOrType')}
            // helperText={touched.assistance && errors.assistance}
            error={touched.assistance && Boolean(errors.assistance)}
            onChange={(value: string) => setFieldValue('assistance', value)}
            defaultValue={assistancesTranslation[i18n.language][0]}
          />
          {renderCompanionsSelect()}
          {renderCompanionInfoInputs()}
        </div>
        <div className={commonStyles.wrapButton}>
          <Button label={t('back')} outlined onClick={onClickBack} />
          <Button label={t('next')} />
        </div>
      </form>
    </div>
  );
};

export default AssistanceForm;
