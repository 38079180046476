import React from 'react';
import './styles/global.styles.scss';
import { Routes, Route } from 'react-router-dom';
import { ScheduleRequest } from './pages';
import { changeLanguage } from './i18n';

const App = () => {
  React.useEffect(() => {
    changeLanguage('en');
  });

  return (
    <div>
      <Routes>
        <Route path="/" element={<ScheduleRequest />} />
      </Routes>
    </div>
  );
};

export default App;
