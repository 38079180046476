import React, { useState } from 'react';
import InputMask from 'react-input-mask';
import Input from '../Input';
import { useStyles } from './styles';

interface Props {
  mask: string | RegExp[];
  placeholder?: string;
  id: string;
  name: string;
  disabled?: boolean;
  value: string;
  onChange?: ({ currentTarget }: React.FormEvent<HTMLInputElement>) => void;
  helperText?: string | false | undefined;
  error?: boolean | undefined;
  text: string;
  onBlur?: any;
  isOptional?: boolean | undefined;
  sx?: {
    width?: number | string;
    height?: number | string;
  };
  maskEnding?: string;
  isMonospace?: boolean;
  renderTip?: () => void;
}

const MaskedInput: React.FC<Props> = (props) => {
  const [maskEndingIsVisible, setMaskEndingIsVisible] = useState(false);
  const { root, valueEnding, inputField } = useStyles();
  const {
    mask,
    placeholder,
    text,
    sx,
    maskEnding,
    onChange,
    isMonospace,
    isOptional = false,
  } = props;

  const onFocus = () => setMaskEndingIsVisible(true);

  const onBlur = () => setMaskEndingIsVisible(false);

  const renderMaskEnding = () =>
    maskEndingIsVisible &&
    maskEnding && (
      <p style={{ marginLeft: mask.length * 12 }} className={valueEnding}>
        {maskEnding}
      </p>
    );

  return (
    <div className={root}>
      {renderMaskEnding()}
      <InputMask onBlur={onBlur} onFocus={onFocus} {...props} onChange={onChange}>
        <Input
          variant="outlined"
          placeholder={placeholder}
          text={text}
          sx={sx}
          isOptional={isOptional}
          className={isMonospace ? inputField : ''}
        />
      </InputMask>
    </div>
  );
};

export default MaskedInput;
