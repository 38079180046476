import { makeStyles } from '@mui/styles';
import { createTheme } from '@mui/material/styles';
import colors from '../../styles/colors';

const { grey, red, darkGrey2 } = colors;

export const useStyles = makeStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      backgroundColor: grey,
    },
    '& .MuiFormControl-root-MuiTextField-root.Mui-error': {
      border: `1px solid ${red}`,
    },
    '& .MuiAutocomplete-root': {
      width: '100%',
    },
    '& .MuiAutocomplete-root .MuiOutlinedInput-root.MuiInputBase-sizeSmall .MuiAutocomplete-input':
      {
        padding: 0,
      },
    '& .MuiAutocomplete-root .MuiOutlinedInput-root.MuiInputBase-sizeSmall': {
      padding: 0,
      paddingTop: 16,
      paddingBottom: 16,
      paddingLeft: 16,
      paddingRight: 16,
      color: darkGrey2,
    },
    '& .MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-endAdornment': {
      right: 15,
    },
    '& .MuiTypography-root': {
      color: red,
      paddingTop: 10,
    },
  },
});

export const theme = createTheme({
  palette: {
    tonalOffset: 0,
    action: {
      active: 'rgba(0, 0, 0, 0)',
      hoverOpacity: 0,
    },
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          backgroundColor: grey,
          color: darkGrey2,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: { root: { color: darkGrey2 } },
    },
  },
});
