import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { Input, Button, MaskedInput, Search } from '../../../../components';
import styles from './styles.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { AppState } from '../../../../types/stores';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTranslation } from 'react-i18next';
import MUIButton from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { AutocompleteOption, LocationInfo, ParsedAddress } from '../../../../types';
import { bindActionCreators } from 'redux';
import { appActions } from '../../../../store/app';
import explodeAddress from '../../../../utils/addressParser/parseAddress.js';
import { isAddressValid, getIsRequestStateBlocking } from '../../../../utils';
import { Typography } from '@mui/material';
import colors from '../../../../styles/colors';
import tipIcon from '../../../../assets/icons/tip.svg';
import commonStyles from '../../../../styles/common.module.scss';
import ReactTooltip from 'react-tooltip';

interface Props {
  onClickBack: () => void;
  onClickNext: () => void;
  setValue: (value: LocationInfo, fieldName: string) => void;
}

const PickUpAddressForm: React.FC<Props> = ({ onClickBack, onClickNext, setValue }) => {
  const [isLocationValid, setIsLocationValid] = useState(true);
  const [isManualEntering, setIsManualEntering] = useState(false);
  const [formIsVisible, setFormIsVisible] = useState(false);
  const {
    city,
    state,
    notes,
    zipCode,
    address1,
    address2,
    latitude,
    longitude,
    addressName,
    searchInput,
    searchLoading,
    searchLocationAutocompleteOptions,
    pickLocationStatus,
  } = useSelector(
    ({
      pickUpLocation,
      searchLoading,
      searchLocationAutocompleteOptions,
      pickLocationStatus,
    }: AppState) => ({
      ...pickUpLocation,
      searchLoading,
      searchLocationAutocompleteOptions,
      pickLocationStatus,
    })
  );
  const { t } = useTranslation();
  const { searchLocation, pickLocation, resetLocation } = bindActionCreators(
    appActions,
    useDispatch()
  );
  const options = searchLocationAutocompleteOptions.map(
    ({ address }: AutocompleteOption) => address
  );
  const matches = useMediaQuery('(max-width:576px)');

  const { setFieldValue, handleSubmit, handleChange, errors, touched, values } = useFormik({
    validationSchema: yup.object({
      address1: yup.string().required(t('pleaseEnterAdress')),
      city: yup.string().required(t('pleaseEnterCity')),
      state: yup.string().required(t('pleaseEnterState')),
      zipCode: yup.string().required(t('pleaseEnterZipCode')),
    }),
    initialValues: {
      address2: address2 || '',
      city: city || '',
      state: state || '',
      notes: notes || '',
      zipCode: zipCode || '',
      address1: address1 || '',
      addressName: addressName || '',
      searchInput: searchInput || '',
    },
    onSubmit: () => {
      setValue(
        {
          latitude,
          longitude,
          searchInput: values.searchInput,
          city: values.city,
          state: values.state,
          notes: values.notes,
          zipCode: values.zipCode,
          address1: values.address1,
          address2: values.address2,
          addressName: values.addressName,
        },
        'pickUpLocation'
      );
      values.address1 && onClickNext();
    },
  });

  useEffect(() => {
    setFieldValue('city', city);
    setFieldValue('state', state);
    setFieldValue('zipCode', zipCode);
    setFieldValue('address1', address1);
  }, [address1]);

  const renderForm = () =>
    (formIsVisible || isManualEntering || address1) && (
      <>
        <Grid item xs={12}>
          <Input
            isOptional
            id="addressName"
            name="addressName"
            placeholder={t('placeholderHome')}
            text={t('textHome')}
            sx={{ width: '100%' }}
            onChange={handleChange}
            value={values.addressName}
          />
        </Grid>
        <Grid item xs={12}>
          <Input
            id="address1"
            name="address1"
            sx={{ width: '100%' }}
            value={values.address1}
            onChange={handleChange}
            text={t('addressLine1')}
            disabled={!isManualEntering}
            placeholder={t('ex') + '145 4th Avenue'}
            helperText={touched.address1 && errors.address1}
            error={touched.address1 && Boolean(errors.address1)}
          />
        </Grid>
        <Grid item xs={12}>
          <Input
            id="address2"
            name="address2"
            onChange={handleChange}
            text={t('addressLine2')}
            sx={{ width: '100%' }}
            value={values.address2}
            placeholder={t('addressLine2Placeholder')}
          />
        </Grid>
        <Grid item xs={matches ? 12 : 5}>
          <Input
            id="city"
            name="city"
            text={t('city')}
            value={values.city}
            sx={{ width: '100%' }}
            onChange={handleChange}
            placeholder="ex. New York"
            disabled={!isManualEntering}
            helperText={touched.city && errors.city}
            error={touched.city && Boolean(errors.city)}
          />
        </Grid>
        <Grid item xs={matches ? 7 : 4}>
          <Input
            disabled={!isManualEntering}
            id="state"
            name="state"
            placeholder="ex. New York"
            text={t('state')}
            sx={{ width: '100%' }}
            value={values.state}
            onChange={handleChange}
            helperText={touched.state && errors.state}
            error={touched.state && Boolean(errors.state)}
          />
        </Grid>
        <Grid item xs={matches ? 5 : 3}>
          <MaskedInput
            disabled={!isManualEntering}
            onChange={handleChange}
            mask="99999"
            id="zipCode"
            name="zipCode"
            text={t('zip')}
            placeholder="ex. 68552"
            sx={{ width: '100%' }}
            value={values.zipCode}
            helperText={touched.zipCode && errors.zipCode}
            error={touched.zipCode && Boolean(errors.zipCode)}
          />
        </Grid>
        <Grid item xs={12}>
          <Input
            multiline
            isOptional
            id="notes"
            name="notes"
            text={t('notes')}
            value={values.notes}
            sx={{ width: '100%' }}
            onChange={handleChange}
            renderTip={renderNotesTip}
            placeholder={t('notesPlaceholder')}
          />
        </Grid>
      </>
    );

  const onClickOption = ({ place_id }: AutocompleteOption) => {
    pickLocation(place_id!, 'pickUpLocation');
    setFormIsVisible(true);
  };

  const validateLocation = () => {
    explodeAddress(values.searchInput, (parsedAddress: ParsedAddress) => {
      if (!isAddressValid(parsedAddress)) {
        setIsLocationValid(false);
      }
    });
  };

  const onEnterLocation = (_: React.SyntheticEvent, value: string) => {
    setIsLocationValid(true);
    setFormIsVisible(false);
    setFieldValue('searchInput', value);
    searchLocation(value);
  };

  const onChange = (option: string) => {
    if (option) {
      const pickedLocation = searchLocationAutocompleteOptions.find(
        ({ address }: AutocompleteOption) => address === option
      );
      onClickOption(pickedLocation!);
    }
  };

  const renderManualEnteringButton = () => (
    <div className={styles.enterManualContainer}>
      <MUIButton
        color={'info'}
        disabled={isManualEntering}
        onClick={() => setIsManualEntering(true)}
      >
        {t('enterManualAddress')}
      </MUIButton>
    </div>
  );

  const renderTipContent = () => (
    <div>
      <p>{t('notesTip')}</p>
    </div>
  );

  const renderNotesTip = () => (
    <>
      <ReactTooltip getContent={renderTipContent} />
      <img
        src={tipIcon}
        data-tip="tooltip"
        data-type="light"
        data-class={commonStyles.tipContainer}
      />
    </>
  );

  const isPickLocationBlocking = getIsRequestStateBlocking(pickLocationStatus);
  const showSubmitLoading = searchLoading || isPickLocationBlocking;

  const searchInputError = touched.searchInput && Boolean(errors.searchInput);

  return (
    <div className={styles.wrap}>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={matches ? 2 : 4} sx={{ padding: matches ? `0 4vw 4vh` : 0 }}>
          <Grid item xs={12}>
            <p className={commonStyles.boldText}>{t('providePickUpAddress')}</p>
            <Search
              id="pickupSearchInput"
              options={options}
              handleChange={onChange}
              loading={searchLoading}
              value={values.searchInput}
              onInputChange={onEnterLocation}
              placeholder={t('ex') + '145 4th Avenue'}
              error={touched.searchInput && Boolean(errors.searchInput)}
            />
            {searchInputError && (
              <Typography color={colors.orange}>{t('pleaseChooseLocation')}</Typography>
            )}
            {renderManualEnteringButton()}
          </Grid>
          {renderForm()}
        </Grid>
        <div className={commonStyles.wrapButton}>
          <Button label={t('back')} outlined onClick={onClickBack} />
          <Button submitLoading={showSubmitLoading} label={t('next')} onClick={validateLocation} />
        </div>
      </form>
    </div>
  );
};

export default PickUpAddressForm;
