import * as React from 'react';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import DateAdapter from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import MUIDatePicker from '@mui/lab/DesktopDatePicker';
import { useStyles, theme } from './styles';
import { ThemeProvider } from '@mui/material/styles';
import { Typography } from '@mui/material';
import { beforeToday } from '../../helpers/constants';
import { IconCalendar } from '../../assets/icons/IconCalendar';
import { LeftArrowButton } from '../../assets/icons/LeftArrowButton';
import { RightArrowButton } from '../../assets/icons/RightArrowButton';
import { SwitchViewButton } from '../../assets/icons/SwitchViewButton';
import moment from 'moment';

interface Props {
  label?: string;
  text: string;
  placeholder?: string;
  value: any;
  onChange: any;
  id: string;
  name: string;
  error: any;
  helperText?: string | false | undefined;
  onBlur: any;
  useMaxDate?: boolean;
  useMinDate?: boolean;
}

const DatePicker: React.FC<Props> = ({
  onChange,
  placeholder,
  value,
  id,
  name,
  error,
  helperText,
  text,
  onBlur,
  useMaxDate,
  useMinDate,
}) => {
  const { root } = useStyles();

  const onDatePicked = (date: any) => onChange(name, date?.format('MM/DD/yyyy') || '');

  const renderInput = (params: TextFieldProps) => {
    return (
      <TextField
        {...params}
        id={id}
        name={name}
        error={Boolean(error)}
        onBlur={onBlur}
        placeholder={placeholder}
        helperText={helperText}
        variant="outlined"
        size="small"
        sx={{ width: '100%' }}
      />
    );
  };
  return (
    <LocalizationProvider dateAdapter={DateAdapter}>
      <Typography>{text}</Typography>
      <ThemeProvider theme={theme}>
        <div className={root}>
          <MUIDatePicker
            value={value}
            maxDate={useMaxDate && beforeToday}
            minDate={useMinDate && moment()}
            onChange={onDatePicked}
            renderInput={renderInput}
            components={{
              OpenPickerIcon: IconCalendar,
              LeftArrowButton: LeftArrowButton,
              RightArrowButton: RightArrowButton,
              SwitchViewButton: SwitchViewButton,
            }}
          />
        </div>
      </ThemeProvider>
    </LocalizationProvider>
  );
};

export default DatePicker;
