import { Timezone } from '../types';
import { timezones } from './constants';

export function getDefaultTimezoneForBrowser() {
  const d = new Date();
  const { st } = getDstOffsets(d);
  const offsetHours = st / 60;

  // NOTE: defaults to ET for higher offsets
  if (offsetHours <= 5) return timezones.ET;
  if (offsetHours <= 6) return timezones.CT;
  if (offsetHours <= 7) return timezones.MT;

  // NOTE: defaults to PT for anything else
  return timezones.PT;
}

export function getDstOffsets(d: Date) {
  const st = new Date(d.getFullYear(), 0, 1).getTimezoneOffset();
  const dst = new Date(d.getFullYear(), 6, 1).getTimezoneOffset();
  return { st, dst };
}

export function isDST(d: Date): boolean {
  const { dst, st } = getDstOffsets(d);
  return Math.max(st, dst) !== d.getTimezoneOffset();
}

/**
 * Returns value padded with zeros to the left.
 *
 * NOTE: Number values are converted to absolute number before padding as
 *       string which results in negative numbers returning positive values.
 *       This is done to make padding work with negative numbers.
 **/
export function padZeros(value: string | number, maxLength = 2): string {
  if (typeof value === 'number') {
    return padZeros(`${Math.abs(value)}`, maxLength);
  }

  return value.padStart(maxLength, '0');
}

/**
 * Returns the hour offset for passed in date and timezone.
 *
 * Tests if the date is during Standard Time (ST) or Daylight Savings Time (DST)
 * to determine timeone's offset to use.
 **/
export function getDateTimeZoneOffset(d: Date, tz: Timezone) {
  const tzOffset = isDST(d) ? tz.dstOffset : tz.offset;
  return tzOffset;
}

/**
 * Returns ISO 8601 formated date time string in UTC time i.e. withOUT timezone
 * offset included. This ignores the timezone offset of the JS Date object that
 * is automatically set to the browser's timezone.
 *
 * Checks if the passed in date object is during Standard Time (ST) or Daylight
 * Saving Time (DST) and applies the appropriate timezone offset `offset` or
 * `dstOffset`.
 *
 * NOTE: The passed in timezone's offset is applied and the returned string is
 *       UTC. The timezone offset is applied and UTC time returned because that
 *       is what the trip estimate API requires.
 **/
export function getIsoDateTime(d: Date, tz: Timezone): string {
  const year = padZeros(`${d.getFullYear()}`);
  const month = padZeros(`${d.getMonth() + 1}`);
  const day = padZeros(`${d.getDate()}`);

  const hours = padZeros(`${d.getHours()}`);
  const minutes = padZeros(`${d.getMinutes()}`);

  const tzOffset = getDateTimeZoneOffset(d, tz);
  const offsetPrefix = tzOffset < 0 ? '-' : '+';
  const isoTzOffset = `${offsetPrefix}${padZeros(tzOffset)}00`;

  const isoDateStr = `${year}-${month}-${day}`;
  const isoTimeStr = `${hours}:${minutes}:00${isoTzOffset}`;
  const isoDateTimeStr = `${isoDateStr}T${isoTimeStr}`;

  const isoDateParsed = Date.parse(isoDateTimeStr);
  const isoDate = new Date(isoDateParsed);
  const isoDateTime = isoDate.toISOString();

  return isoDateTime;
}
