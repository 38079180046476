import React from 'react';
import { Public } from '@mui/icons-material';
import { Chip } from '@mui/material';
import { useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { AppState } from '../../types/stores';
import { useStyles } from './styles';

const TimezoneOffset: React.FC = () => {
  const timezone = useSelector(({ timezone }: AppState) => timezone);

  const { root } = useStyles();

  return (
    <div className={root}>
      <Chip
        size="small"
        label={timezone.code}
        data-tip={timezone.name}
        data-effect="solid"
        icon={<Public />}
        variant="outlined"
      />
      <ReactTooltip />
    </div>
  );
};

export default TimezoneOffset;
