import React from 'react';
import { useStyles, theme } from './styles';
import { MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material';
import { IconSelect } from '../../assets/icons/IconSelect';
import { ThemeProvider } from '@mui/material/styles';

interface Props {
  id?: string;
  value?: string;
  onChange: (data: any) => void;
  valueArray: any[];
  error?: any;
  helperText?: string | false | undefined;
  placeholder?: string;
  text?: string;
  renderCustomMenu?: (value: any, index: number) => void;
  defaultValue?: any;
}

const SelectComp: React.FC<Props> = ({
  id,
  value,
  onChange,
  valueArray,
  error,
  helperText,
  placeholder,
  renderCustomMenu,
  defaultValue,
}) => {
  const { root } = useStyles();

  const renderMenu = (value: string, id: number) => (
    <MenuItem key={id} value={value}>
      {value}
    </MenuItem>
  );

  return (
    <ThemeProvider theme={theme}>
      <div className={root}>
        <Select
          id={id}
          error={error}
          value={value}
          displayEmpty
          defaultValue={defaultValue}
          IconComponent={IconSelect}
          sx={{ width: '100%' }}
          inputProps={{ 'aria-label': 'Without label' }}
          onChange={({ target }: SelectChangeEvent) => onChange(target.value)}
          renderValue={(selected) => {
            if (selected.length === 0) {
              return <p style={{ padding: 0, margin: 0, color: '#8AA3B9' }}>{placeholder}</p>;
            }
            return selected;
          }}
        >
          {valueArray.map(renderCustomMenu || renderMenu)}
        </Select>
        <Typography>{helperText}</Typography>
      </div>
    </ThemeProvider>
  );
};

export default SelectComp;
