import { makeStyles } from '@mui/styles';
import colors from '../../styles/colors';
import { createTheme } from '@mui/material/styles';

const { grey, blue, red, darkGrey2 } = colors;

export const useStyles = makeStyles({
  root: {
    '& .MuiOutlinedInput-input': {
      border: 'none',
      borderRadius: 4,
      paddingTop: 16,
      paddingLeft: 16,
      paddingBottom: 16,
      paddingRight: 16,
      backgroundColor: grey,
      cursor: 'pointer',
      color: darkGrey2,
    },
    '& .MuiOutlinedInput-root.Mui-focused': {
      border: `1px solid ${blue}`,
    },
    '& .css-1qxw4jt-MuiInputBase-root-MuiOutlinedInput-root': {
      padding: 0,
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
      border: `1px solid ${red}`,
    },
    '& .MuiOutlinedInput-root.Mui-error.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: `0px solid ${blue}`,
    },
    '& .MuiFormHelperText-root.Mui-error': {
      color: red,
    },
    '& .MuiTypography-root': {
      fontFamily: 'Mulish',
      fontSize: 16,
      marginBottom: 6,
    },
    '& .MuiOutlinedInput-root': {
      padding: 0,
    },
  },
  optionalText: {
    fontSize: 12,
    fontFamily: 'Mulish',
    margin: 0,
    paddingBottom: 5,
  },
  wrapText: {
    display: 'flex',
    alignItems: 'center',
  },
});

export const theme = createTheme({
  palette: {
    text: {
      disabled: darkGrey2,
    },
  },
});
