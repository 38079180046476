import React from 'react';
import { Search, Button, Input } from '../../../../components';
import { purposeTranslation } from '../../../../i18n/translationPurpose';
import { useFormik } from 'formik';
import styles from './styles.module.scss';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { LanguageOption } from '../../../../types/index';
import i18n from 'i18next';
import commonStyles from '../../../../styles/common.module.scss';
import useMediaQuery from '@mui/material/useMediaQuery';
import Grid from '@mui/material/Grid';
import { useSelector } from 'react-redux';
import { AppState } from '../../../../types/stores';

interface Props {
  specifyOtherPurpose: string;
  onClick: (value: any, fieldName: string) => void;
  onClickBack: () => void;
  onClickNext: () => void;
}

const ChoosePurposeForm: React.FC<Props> = ({
  onClick,
  onClickBack,
  onClickNext,
  specifyOtherPurpose,
}) => {
  const { t } = useTranslation();
  const purposeOptions = purposeTranslation[i18n.language as LanguageOption];
  const valueArray = purposeOptions.map(({ value }) => value);
  const { purposeVisit } = useSelector((state: AppState) => state);

  const { values, errors, handleBlur, touched, handleSubmit, setFieldValue } = useFormik({
    validationSchema: yup.object({
      purposeVisit: yup.string().required(t('pleaseSelectOne')),
      specifyText: yup.string().when('purposeVisit', {
        is: (purposeVisit: string) => purposeVisit === t('other'),
        then: yup.string().required(t('pleaseSpecify')),
      }),
    }),
    initialValues: {
      purposeVisit: purposeVisit.value || '',
      specifyText: specifyOtherPurpose || '',
    },
    onSubmit: () => {
      onClick(
        purposeOptions.find(({ value }) => value === values.purposeVisit),
        'purposeVisit'
      );
      onClick(values.specifyText, 'specifyOtherPurpose');
      onClickNext();
    },
  });

  const handleChange = (name: any) => setFieldValue('purposeVisit', name);

  const matches = useMediaQuery('(max-width:576px)');
  const renderItem = () => (
    <Grid container spacing={4}>
      <Grid item xs={matches ? 12 : 8}>
        <Input
          placeholder={t('placeholderSpecify')}
          id="specifyText"
          name="specifyText"
          value={values.specifyText}
          onChange={handleChange}
          onSelect={handleChange}
          error={touched.specifyText && Boolean(errors.specifyText)}
          helperText={touched.specifyText && errors.specifyText}
          onBlur={handleBlur}
          sx={{ width: '100%' }}
          inputProps={{ maxLength: 50 }}
        />
      </Grid>
    </Grid>
  );
  return (
    <div className={styles.wrap}>
      <form onSubmit={handleSubmit}>
        <div className={styles.container}>
          <p className={commonStyles.boldText}>{t('selectPurpose')}</p>
          <Search
            id="serch-purpose"
            options={valueArray}
            handleChange={handleChange}
            value={values.purposeVisit}
            placeholder={t('selectOrType')}
            helperText={touched.purposeVisit && errors.purposeVisit}
            error={touched.purposeVisit && Boolean(errors.purposeVisit)}
          />
          {values.purposeVisit === t('other') && renderItem()}
        </div>
        <div className={commonStyles.wrapButton}>
          <Button label={t('back')} outlined onClick={onClickBack} />
          <Button label={t('next')} />
        </div>
      </form>
    </div>
  );
};

export default ChoosePurposeForm;
