/* eslint-disable no-prototype-builtins */
import { nextTick } from 'process';
import mapStates from './lib/states.json';
import mapCountries from './lib/countries.json';

export default (singleLineAddress, cb) => {
  nextTick(() => {
    const addressObj = {
      street_address: null,
      city: null,
      state: null,
      postal_code: null,
      country: null,
    };

    if (typeof singleLineAddress !== 'string') return cb(addressObj);

    singleLineAddress = singleLineAddress.trim();

    let postalCode = singleLineAddress.match(/([0-9]{5})|([a-z][0-9][a-z] ?[0-9][a-z][0-9])/gi),
      indexOfPostalCode = -1;

    if (postalCode) {
      postalCode = postalCode.pop();
      indexOfPostalCode = singleLineAddress.lastIndexOf(postalCode);

      if (indexOfPostalCode == 0 && singleLineAddress.length > 10) {
        postalCode = null;
        indexOfPostalCode = -1;
      }

      if (postalCode) {
        addressObj.postal_code = postalCode;

        const everythingAfterPostalCode = singleLineAddress.substr(
          indexOfPostalCode + postalCode.length
        );

        singleLineAddress =
          singleLineAddress.substr(0, indexOfPostalCode) + everythingAfterPostalCode;

        const possibleCountry = everythingAfterPostalCode
          .replace(/\s*,/, '')
          .split(',')
          .shift()
          .trim();

        if (possibleCountry && looksLikeCountry(possibleCountry)) {
          addressObj.country = possibleCountry;
          singleLineAddress = singleLineAddress.substr(0, indexOfPostalCode);
        }
      }
    }

    const addySplit = singleLineAddress.split(',');

    if (addySplit.length == 3 && looksLikeState(addySplit[2])) {
      addressObj.street_address = addySplit[0].trim();
      addressObj.city = addySplit[1].trim();
      addressObj.state = addySplit[2].trim();
      return cb(addressObj);
    }

    addySplit.forEach(function (addyPart) {
      if (!(addyPart = addyPart.trim())) return;

      if (/[0-9]/.test(addyPart)) {
        return !addressObj.street_address && (addressObj.street_address = addyPart);
      }

      if (looksLikeState(addyPart) && !addressObj.state) {
        return (addressObj.state = addyPart);
      }

      if (looksLikeCountry(addyPart)) {
        return !addressObj.country && (addressObj.country = addyPart);
      }

      !addressObj.city && (addressObj.city = addyPart);
    });

    cb(addressObj);
  });
};

let states;
const looksLikeState = (str) => {
  if (!states) {
    states = {};
    for (const k in mapStates) {
      if (mapStates.hasOwnProperty(k)) {
        states[k.toLowerCase()] = true;
        states[mapStates[k].toLowerCase()] = true;
      }
    }
  }
  str = str.trim().toLowerCase();
  return !!states[str];
};

let countries;
const looksLikeCountry = (str) => {
  if (!countries) {
    countries = {};
    for (const k in mapCountries) {
      if (mapCountries.hasOwnProperty(k)) {
        countries[k.toLowerCase()] = true;
        countries[mapCountries[k].toLowerCase()] = true;
      }
    }
  }
  str = str.trim().toLowerCase();

  if (str === 'usa') {
    return true;
  }

  return !!countries[str];
};
