import { applyMiddleware, createStore } from 'redux';
import Thunk from 'redux-thunk';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'react-router-redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { appReducer } from './app';

const history = createBrowserHistory();
const middlewares = [Thunk, routerMiddleware(history)];

export default createStore(appReducer, composeWithDevTools(applyMiddleware(...middlewares)));
