import React from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import { ThemeProvider } from '@mui/material/styles';
import { CircularProgress } from '@mui/material';
import { useStyles, theme } from './styles';

interface Props {
  label: string;
  outlined?: boolean;
  onClick?: () => void;
  onSubmit?: () => void;
  submitLoading?: boolean;
}

const Button: React.FC<Props> = ({ label, onClick, outlined, submitLoading = false }) => {
  const { root } = useStyles();
  const buttonVariant = outlined ? 'outlined' : 'contained';
  const type = outlined ? 'button' : 'submit';

  const renderCircleProgress = () => <CircularProgress color="inherit" size={16} />;

  return (
    <ThemeProvider theme={theme}>
      <div className={root}>
        <LoadingButton
          variant={buttonVariant}
          onSubmit={onClick}
          onClick={onClick}
          type={type}
          loading={submitLoading}
          loadingIndicator={renderCircleProgress()}
        >
          {label}
        </LoadingButton>
      </div>
    </ThemeProvider>
  );
};

export default Button;
