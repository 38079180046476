import { assistancesEn } from './assistancesEn';
import { assistancesEs } from './assistancesEs';
import { assistancesCmn } from './assistancesCmn';
import { assistancesKo } from './assistancesKo';
import { assistancesVi } from './assistancesVi';
import { assistancesYue } from './assistancesYue';
import { Translations } from '../../types';

export const assistancesTranslation: Translations = {
  en: assistancesEn,
  es: assistancesEs,
  cmn: assistancesCmn,
  ko: assistancesKo,
  vi: assistancesVi,
  yue: assistancesYue,
};
