import { HealthPlan, Language, Timezone } from '../types';
import { getCentralHealthInputMask } from '../utils';
import moment from 'moment';
import bndCard from '../assets/images/memberCards/brand-new-day-card.jpg';
import chmpCard from '../assets/images/memberCards/CHMP.jpg';
import cleverCareCard from '../assets/images/memberCards/CCHP.jpg';
import goldKidneyCard from '../assets/images/memberCards/GoldKidney.jpg';
import humanaCard from '../assets/images/memberCards/Humana.jpg';
import ihpCard from '../assets/images/memberCards/IHP.jpg';
import iihcpCard from '../assets/images/memberCards/IIHCP.jpg';
import ivhpCard from '../assets/images/memberCards/IVHP.jpg';
import verdaCard from '../assets/images/memberCards/Verda.jpg';
import { getDefaultTimezoneForBrowser } from './datetime';

export const STEPS_COUNT = 11;

export const dateFormats = {
  appoitmentDatePlaceholder: 'mm/dd/yyyy',
  validAppoitmentDate: 'MMM Do YYYY',
  suggestedTripTime: 'h:mm A',
  suggestedTripDate: 'MMMM Do, YYYY',
};

export const beforeToday = moment().subtract(1, 'day');

export const timezones: Record<string, Timezone> = {
  ET: {
    code: 'ET',
    name: 'Eastern Time',
    offset: -5,
    dstOffset: -4,
    tzIdentifier: 'America/New_York',
  },
  CT: {
    code: 'CT',
    name: 'Central Time',
    offset: -6,
    dstOffset: -5,
    tzIdentifier: 'America/Chicago',
  },
  MT: {
    code: 'MT',
    name: 'Mountain Time',
    offset: -7,
    dstOffset: -6,
    tzIdentifier: 'America/Denver',
  },
  PT: {
    code: 'PT',
    name: 'Pacific Time',
    offset: -8,
    dstOffset: -7,
    tzIdentifier: 'America/Los_Angeles',
  },
};

export const defaultTimezone = getDefaultTimezoneForBrowser();

export const timezoneOptions: Timezone[] = [timezones.ET, timezones.CT, timezones.MT, timezones.PT];

export const langOptions: Language[] = [
  { name: 'English', code: 'en' },
  { name: 'Español', code: 'es' },
  { name: 'Tiếng việt', code: 'vi' },
  { name: '한국어 [韓國語] (han-guk-eo)', code: 'ko' },
  { name: '廣東話', code: 'yue' },
  { name: '普通话', code: 'cmn' },
];

export const unavailableHealthPlans: HealthPlan[] = [
  {
    name: 'Aetna',
    cardExample: undefined,
    memberIdMaxLength: undefined,
    placeholder: 'ex.',
    inputMask: undefined,
    isUnavailable: true,
  },
  {
    name: 'Anthem',
    cardExample: undefined,
    memberIdMaxLength: undefined,
    placeholder: 'ex.',
    inputMask: undefined,
    isUnavailable: true,
  },
  {
    name: 'Brand New Day (BND)',
    nameMobile: 'Brand New Day',
    cardExample: bndCard,
    memberIdMaxLength: 7,
    inputMask: '*******',
    maskEnding: '*01',
    placeholder: '0497837',
    isUnavailable: true,
  },
  {
    name: 'Central Health (CHMP)',
    nameMobile: 'Central Health',
    cardExample: chmpCard,
    memberIdMaxLength: 12,
    inputMask: getCentralHealthInputMask(),
    placeholder: '047836047836',
    isUnavailable: true,
  },
  {
    name: 'Inter Valley Health Plan (IVHP)',
    nameMobile: 'Inter Valley Health Plan',
    cardExample: ivhpCard,
    memberIdMaxLength: 9,
    inputMask: '999999999',
    maskEnding: '*00',
    placeholder: '038476837',
    isUnavailable: true,
  },
  {
    name: 'United Health',
    cardExample: undefined,
    memberIdMaxLength: undefined,
    placeholder: 'ex.',
    inputMask: undefined,
    isUnavailable: true,
  },
];

export const healthPlans: HealthPlan[] = [
  {
    name: 'Humana',
    cardExample: humanaCard,
    memberIdMaxLength: 9,
    inputMask: 'H99999999',
    placeholder: 'H12345678',
  },
  {
    name: 'Clever Care',
    cardExample: cleverCareCard,
    memberIdMaxLength: 10,
    inputMask: '0000099999',
    placeholder: '0000008273',
  },
  {
    name: 'Gold Kidney',
    cardExample: goldKidneyCard,
    memberIdMaxLength: 8,
    inputMask: '89999999',
    placeholder: '80090099',
  },
  {
    name: 'Innovative Integrated Health Community Plans (IIHCP)',
    nameMobile: 'Innovative Integrated Health Community Plans',
    cardExample: iihcpCard,
    memberIdMaxLength: 9,
    // TODO: replace mask for this plan
    inputMask: 'IHC999999',
    placeholder: 'IHC048264',
  },
  {
    name: 'Imperial Health Plan (IHP)',
    cardExample: ihpCard,
    memberIdMaxLength: 9,
    inputMask: 'aaa999999',
    placeholder: 'ICA012345',
  },
  {
    name: 'Verda Healthcare',
    cardExample: verdaCard,
    memberIdMaxLength: 9,
    inputMask: 'V\\9\\9999999',
    placeholder: 'V99000000',
  },
];
