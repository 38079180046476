import React from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import moment from 'moment';
import { Input, Button, DatePicker } from '../../../../components';
import styles from './styles.module.scss';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useSelector } from 'react-redux';
import { AppState } from '../../../../types/stores';
import { beforeToday } from '../../../../helpers/constants';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import commonStyles from '../../../../styles/common.module.scss';

interface Props {
  onClickBack: () => void;
  onClickNext: () => void;
  setValue: (value: string, fieldName: string) => void;
}

const MemberDetailsForm: React.FC<Props> = ({ onClickBack, onClickNext, setValue }) => {
  const { firstName, secondName, birthDate } = useSelector((state: AppState) => state);
  const { t } = useTranslation();
  const matches = useMediaQuery('(max-width:576px)');

  const formik = useFormik({
    initialValues: {
      firstName: firstName || '',
      secondName: secondName || '',
      birthDate: birthDate || '',
    },
    validationSchema: yup.object({
      firstName: yup
        .string()
        .max(50, 'First Name must be at most 50 characters')
        .required(t('fieldCantBeEmpty')),
      secondName: yup
        .string()
        .max(50, 'Last Name must be at most 50 characters')
        .required(t('fieldCantBeEmpty')),
      birthDate: yup
        .date()
        .typeError(t('enterValidDate'))
        .required(t('fieldCantBeEmpty'))
        .test('startDate', t('enterValidData2'), (value) => {
          const date = moment(value);
          return beforeToday.diff(date) > 0;
        }),
    }),
    onSubmit: () => {
      setValue(formik.values.firstName, 'firstName');
      setValue(formik.values.secondName, 'secondName');
      setValue(formik.values.birthDate, 'birthDate');
      onClickNext();
    },
  });
  return (
    <div className={styles.wrap}>
      <form onSubmit={formik.handleSubmit}>
        <div>
          <p className={commonStyles.boldText}>{t('provideDetails')}</p>
          <Grid container spacing={4} sx={{ padding: matches ? `0 4vw 4vh` : 0 }}>
            <Grid item xs={matches ? 12 : 6}>
              <Input
                placeholder={t('yourFirstName')}
                text={t('firstName')}
                id="firstName"
                name="firstName"
                value={formik.values.firstName}
                onChange={formik.handleChange}
                error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                helperText={formik.touched.firstName && formik.errors.firstName}
                onBlur={formik.handleBlur}
                sx={{ width: '100%' }}
                inputProps={{ maxLength: 50 }}
              />
            </Grid>
            <Grid item xs={matches ? 12 : 6}>
              <Input
                placeholder={t('yourSecondName')}
                text={t('secondName')}
                id="secondName"
                name="secondName"
                value={formik.values.secondName}
                onChange={formik.handleChange}
                error={formik.touched.secondName && Boolean(formik.errors.secondName)}
                helperText={formik.touched.secondName && formik.errors.secondName}
                onBlur={formik.handleBlur}
                sx={{ width: '100%' }}
                inputProps={{ maxLength: 50 }}
              />
            </Grid>
            <Grid item xs={matches ? 12 : 6}>
              <DatePicker
                label="birthDate"
                text={t('birthDate')}
                useMaxDate
                id="birthDate"
                name="birthDate"
                value={formik.values.birthDate}
                onChange={formik.setFieldValue}
                error={!!formik.touched.birthDate && Boolean(formik.errors.birthDate)}
                helperText={formik.touched.birthDate && formik.errors.birthDate}
                onBlur={formik.handleBlur}
              />
            </Grid>
          </Grid>
        </div>
        <div className={commonStyles.wrapButton}>
          <Button label={t('back')} outlined onClick={onClickBack} />
          <Button label={t('next')} />
        </div>
      </form>
    </div>
  );
};

export default MemberDetailsForm;
