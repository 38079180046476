import { makeStyles } from '@mui/styles';
import { createTheme } from '@mui/material/styles';
import colors from '../../styles/colors';

const { grey, red, blue, darkGrey, darkGrey2 } = colors;

export const useStyles = makeStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      border: 'none',
      borderRadius: 4,
      background: grey,
      paddingTop: 16,
      paddingLeft: 16,
      paddingBottom: 16,
      cursor: 'pointer',
    },
    '& .MuiOutlinedInput-root.Mui-focused': {
      border: `1px solid ${blue}`,
    },
    '& .MuiOutlinedInput-root.Mui-error': {
      border: `1px solid ${red}`,
    },
    '& .MuiOutlinedInput-root.Mui-error.Mui-focused': {
      border: `1px solid ${blue}`,
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '& .MuiFormHelperText-root.Mui-error': {
      color: red,
    },
    '& .MuiOutlinedInput-input': {
      padding: 0,
      color: darkGrey2,
    },
    '& .MuiInputBase-input-MuiOutlinedInput-input': {
      color: darkGrey,
    },
  },
});

export const theme = createTheme({
  palette: {
    primary: {
      main: blue,
    },
    tonalOffset: 0,
    action: {
      active: 'rgba(0, 0, 0, 0)',
      hoverOpacity: 0,
    },
  },
  components: {
    MuiPaper: {
      styleOverrides: { root: { color: darkGrey2 } },
    },
    MuiSvgIcon: {
      styleOverrides: { root: { marginTop: 20 } },
    },
    MuiInputAdornment: {
      styleOverrides: { root: { marginTop: -15 } },
    },
  },
});
