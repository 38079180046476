import React from 'react';
import { Button } from '../../../../components';
import styles from './styles.module.scss';
import Logo from '../../../../assets/images/CareCarImg.png';
import { useTranslation } from 'react-i18next';
import commonStyles from '../../../../styles/common.module.scss';

interface Props {
  onClickNext: () => void;
  onClickBack: () => void;
}

const StartForm: React.FC<Props> = ({ onClickNext, onClickBack }) => {
  const { t } = useTranslation();
  return (
    <div className={styles.wrap}>
      <div className={styles.container}>
        <img src={Logo} alt="logo" className={styles.logoImg} />
        <p className={`${commonStyles.boldText} ${styles.widthText}`}>
          {t('scheduleYourRide').toUpperCase()}
        </p>
        <p className={styles.importanttext}>
          <strong>{t('importantNote')} </strong> <br />
          {t('ifYouAppointment')}
          <strong> (844) 743 4344</strong>
        </p>
        <p className={styles.importanttext}>
          <strong>{t('note2Min')}</strong>
        </p>
      </div>
      <div className={commonStyles.wrapButton}>
        <Button label={t('back')} outlined onClick={onClickBack} />
        <Button label={t('start')} onClick={onClickNext} />
      </div>
    </div>
  );
};

export default StartForm;
