import React from 'react';
import MUIStepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { StepIconProps } from '@mui/material/StepIcon';
import {
  ColorlibConnector,
  ColorlibSubStepIconRoot,
  ColorlibStepIconRoot,
  useStyles,
} from './styles';
import { Typography } from '@mui/material';
import { STEPS_COUNT } from '../../helpers/constants';
import { isEven } from '../../utils';
import cancelIcon from '../../assets/icons/cancel.svg';
import { bindActionCreators } from 'redux';
import { appActions } from '../../store/app';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

interface StepperProps {
  activeStep: number;
}

const Stepper: React.FC<StepperProps> = ({ activeStep }) => {
  const { root, cancelContainer } = useStyles();
  const { cancelScheduling } = bindActionCreators(appActions, useDispatch());
  const { t } = useTranslation();

  const ColorlibStepIcon = (props: StepIconProps, index: number) => {
    const { active, completed, className } = props;

    return (
      <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
        <Typography>{index * 0.5 + 1}</Typography>
      </ColorlibStepIconRoot>
    );
  };

  const ColorlibSubStepIcon = (props: StepIconProps) => {
    const { active, completed, className } = props;

    return <ColorlibSubStepIconRoot ownerState={{ completed, active }} className={className} />;
  };

  const renderStep = (_: any, index: number) => {
    const icon = isEven(index) ? ColorlibStepIcon : ColorlibSubStepIcon;

    return (
      <Step key={index}>
        <StepLabel StepIconComponent={(props: StepIconProps) => icon(props, index)} />
      </Step>
    );
  };

  const renderContent = () => (
    <MUIStepper activeStep={activeStep} connector={<ColorlibConnector />}>
      {[...Array(STEPS_COUNT)].map(renderStep)}
    </MUIStepper>
  );

  const renderCancelButton = () => (
    <div className={cancelContainer} onClick={onPressCancel}>
      <img src={cancelIcon} />
    </div>
  );

  const onPressCancel = () => confirm(t('cancelWarning')) && cancelScheduling();

  return (
    <div className={root}>
      {renderCancelButton()}
      {renderContent()}
    </div>
  );
};

export default Stepper;
