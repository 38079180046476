import { AutocompleteOption, LocationDetailsResponse, TripEstimation } from '../../types';

export const CANCEL_SCHEDULING = 'CANCEL_SCHEDULING';
export const CLICK_BACK = 'CLICK_BACK';
export const CLICK_NEXT = 'CLICK_NEXT';
export const ESTIMATE_TRIP = 'ESTIMATE_TRIP';
export const ESTIMATE_TRIP_FAIL = 'ESTIMATE_TRIP_FAIL';
export const ESTIMATE_TRIP_SUCCESS = 'ESTIMATE_TRIP_SUCCESS';
export const PICK_LOCATION = 'PICK_LOCATION';
export const PICK_LOCATION_FAIL = 'PICK_LOCATION_FAIL';
export const PICK_LOCATION_SUCCESS = 'PICK_LOCATION_SUCCESS';
export const REQUEST_TRIP = 'REQUEST_TRIP';
export const REQUEST_TRIP_FAIL = 'REQUEST_TRIP_FAIL';
export const REQUEST_TRIP_SUCCESS = 'REQUEST_TRIP_SUCCESS';
export const RESET_LOCATION = 'RESET_LOCATION';
export const SEARCH_LOCATION = 'SEARCH_LOCATION';
export const SEARCH_LOCATION_CLEAR = 'SEARCH_LOCATION_CLEAR';
export const SEARCH_LOCATION_FAIL = 'SEARCH_LOCATION_FAIL';
export const SEARCH_LOCATION_SUCCESS = 'SEARCH_LOCATION_SUCCESS';
export const SELECT_PLAN = 'SELECT_PLAN';
export const SET_FIELD_VALUE = 'SET_FIELD_VALUE';

export type ActionTypes =
  | { type: typeof CANCEL_SCHEDULING }
  | { type: typeof CLICK_BACK }
  | { type: typeof CLICK_NEXT }
  | { type: typeof ESTIMATE_TRIP }
  | { type: typeof ESTIMATE_TRIP_FAIL }
  | { type: typeof ESTIMATE_TRIP_SUCCESS; payload: TripEstimation }
  | { type: typeof PICK_LOCATION; locationType: string }
  | { type: typeof PICK_LOCATION_FAIL; locationType: string }
  | { type: typeof PICK_LOCATION_SUCCESS; payload: LocationDetailsResponse; locationType: string }
  | { type: typeof REQUEST_TRIP }
  | { type: typeof REQUEST_TRIP_FAIL }
  | { type: typeof REQUEST_TRIP_SUCCESS; referenceNumberId: string }
  | { type: typeof RESET_LOCATION; locationType: string }
  | { type: typeof SEARCH_LOCATION }
  | { type: typeof SEARCH_LOCATION_CLEAR }
  | { type: typeof SEARCH_LOCATION_FAIL }
  | { type: typeof SEARCH_LOCATION_SUCCESS; payload: AutocompleteOption[] }
  | { type: typeof SELECT_PLAN; payload: number }
  | { type: typeof SET_FIELD_VALUE; fieldName: string; payload: any };
