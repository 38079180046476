import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export const RightArrowButton = (props: any) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.00007 0L0.825073 1.175L4.64174 5L0.825073 8.825L2.00007 10L7.00007 5L2.00007 0Z"
      fill="#3D5E7B"
    />
  </SvgIcon>
);
